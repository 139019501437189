import getQueryParams from "../../utils/skus/getQueryParams";
import { DIMENSIONS } from "./constants";

export const shortenSkuName = (x) => {
  if (x != null && x.length > 14) return x.slice(0, 14).trim().concat("...");
  return x;
};

export const dimensionNameAccessor = (tableRow, dimension) => {
  if (!tableRow) return "";
  switch (dimension) {
    case DIMENSIONS.brands:
    case DIMENSIONS.sellers:
    case DIMENSIONS.priceRange:
    case DIMENSIONS.attribute:
    case DIMENSIONS.productTypes:
    case DIMENSIONS.rootdomain:
    case DIMENSIONS.manufacturer:
      return tableRow?.name;
    case DIMENSIONS.sku:
    case DIMENSIONS.family:
      return tableRow?.skuData?.name;
    default:
      return "";
  }
};

export const dimensionDataKey = (tableRow, dimension) => {
  if (!tableRow) return null;
  switch (dimension) {
    case DIMENSIONS.brands:
    case DIMENSIONS.sellers:
    case DIMENSIONS.priceRange:
    case DIMENSIONS.attribute:
    case DIMENSIONS.manufacturer:
    case DIMENSIONS.productTypes:
      return tableRow?.name;
    case DIMENSIONS.sku:
    case DIMENSIONS.family:
      return tableRow?.skuData?.sku;
    default:
      return null;
  }
};

export const displayNameAccessor = (tableRow, dimension) => {
  const tableRowName = dimensionNameAccessor(tableRow, dimension);
  switch (dimension) {
    case DIMENSIONS.sku:
    case DIMENSIONS.family:
      return shortenSkuName(tableRowName);
    default:
      return tableRowName;
  }
};
export const hasSelectedCategories = (currentViewConfig) => {
  var productAttributes = currentViewConfig?.query?.productAttributes;
  if (!productAttributes) {
    return false;
  }
  const permittedFilters = ["mb_category", "text", "brand.keyword"];
  return productAttributes.some((item) =>
    permittedFilters.includes(item.mbName)
  );
};

export const hasSelectedRetailers = (currentViewConfig) => {
  return (
    currentViewConfig?.query?.productAttributes?.find(
      (x) => x.mbName == "rootdomain.keyword"
    )?.values?.length > 0
  );
};
