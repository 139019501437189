import _ from "lodash";
import {
  APPLY_INITIAL_STATE,
  FETCH_TABLE_REQUEST,
  FETCH_TABLE_SUCCESS,
  FETCH_TABLE_FAILURE,
  FETCH_TABLE_CANCEL,
  FETCH_MORE_TABLE_REQUEST,
  FETCH_MORE_TABLE_SUCCESS,
  FETCH_MORE_TABLE_FAILURE,
  FETCH_MORE_TABLE_CANCEL,
  FETCH_GRAPH_REQUEST,
  FETCH_GRAPH_SUCCESS,
  FETCH_GRAPH_FAILURE,
  FETCH_GRAPH_CANCEL,
  ADD_SELECTED_SEARCH_TERM_GRAPH,
  REMOVE_SELECTED_SEARCH_TERM_GRAPH,
  DEFAULT_GRAPH_OPTIONS,
  CHANGE_SELECTED_OPTIONS_PER_GRAPH,
} from "./constants";

const initialState = {
  tableData: [],
  graphData: [],
  isLoading: false,
  loadingMore: false,
  hasMore: false,
  selectedSearchTermsGraphs: [],
  graphOptions: {},
};

const brandPerformanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case FETCH_TABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
        tableData: [],
        graphData: [],
      };

    case FETCH_TABLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        tableData: data,
      };
    }

    case FETCH_TABLE_CANCEL:
      return {
        ...state,
        isLoading: false,
        tableData: null,
      };

    case FETCH_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        tableData: null,
      };

    case FETCH_GRAPH_REQUEST: {
      const { searchTerm, id } = action.payload;
      return {
        ...state,
        graphData: {
          ...state.graphData,
          [searchTerm]: {
            ...state.graphData[searchTerm],
            [id]: null,
          },
        },
      };
    }

    case FETCH_GRAPH_SUCCESS: {
      const { searchTerm, id, data } = action.payload;
      return {
        ...state,
        graphData: {
          ...state.graphData,
          [searchTerm]: {
            ...state.graphData[searchTerm],
            [id]: data,
          },
        },
      };
    }

    case FETCH_GRAPH_CANCEL: {
      const { searchTerm, id } = action.payload;
      return {
        ...state,
        graphData: {
          ...state.graphData,
          [searchTerm]: {
            ...state.graphData[searchTerm],
            [id]: null,
          },
        },
      };
    }

    case FETCH_GRAPH_FAILURE: {
      const { searchTerm, id } = action.payload;
      return {
        ...state,
        graphData: {
          ...state.graphData,
          [searchTerm]: {
            ...state.graphData[searchTerm],
            [id]: null,
          },
        },
      };
    }

    case CHANGE_SELECTED_OPTIONS_PER_GRAPH: {
      const { searchTerm, id, value } = action.payload;
      return {
        ...state,
        graphOptions: {
          ...state.graphOptions,
          [searchTerm]: state.graphOptions[searchTerm].map((item, index) =>
            index === id ? value : item
          ),
        },
      };
    }

    case ADD_SELECTED_SEARCH_TERM_GRAPH: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        selectedSearchTermsGraphs: [
          ...state.selectedSearchTermsGraphs,
          searchTerm,
        ],
        graphOptions: {
          ...state.graphOptions,
          [searchTerm]: DEFAULT_GRAPH_OPTIONS,
        },
      };
    }

    case REMOVE_SELECTED_SEARCH_TERM_GRAPH: {
      const { searchTerm } = action.payload;
      return {
        ...state,
        selectedSearchTermsGraphs: state.selectedSearchTermsGraphs.filter(
          (item) => item !== searchTerm
        ),
      };
    }
  }

  return state;
};

export default brandPerformanceReducer;
