import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CatalogTrackerTable from "./components/catalog-tracker-table";
import MbBasicFilters from "../../mb_components/mb-basic-filters/mb-basic-filters";

import styles from "./catalog-tracker.scss";

import utils from "../../utils";

import {
  CATALOG_TRACKER_VALUE_TYPE,
  CATALOG_TRACKER_VIEW_TYPE_DATA,
  CELL_VALUE_CLASSIFICATION,
} from "./constants";
import MarketTrendsButtons from "../../mb_components/market-trends-buttons/market-trends-buttons";
import InfiniteDataTable from "../../components/infinite-data-table/infinite-data-table";

import {
  updateMainRootdomain,
  getMatchedSkus,
  getMoreMatchedSkus,
} from "./actions";

import { showSidebarFilter } from "../../containers/insights/actions";

import chevron from "../../images/chevron.svg";

import {
  updateCurrentViewConfigQuery,
  setCurrentViewIsUpdated,
} from "../insights/insights-data-controls/actions";

const { objectsEqual } = utils.other;

const BASIC_FILTERS_DISPLAY_NAMES = [
  "Category",
  "Retailer",
  "Store Type",
  "Store Name",
  "Region",
  "State",
  "City",
  "Brand",
];
const BASIC_FILTERS_MB_NAMES = [
  "mb_category",
  "product_kpis.rootdomain.keyword",
  "product_kpis.store_type",
  "product_kpis.store_name",
  "product_kpis.region",
  "product_kpis.state",
  "product_kpis.city",
  "brand.keyword",
];

const CatalogTrackerInfiniteTable = (props) => {
  const {
    matchesList,
    isLoading,
    insightsFiltersCollection,
    viewDefinitions,
    marketTrendsConfigs,
    currentViewConfig,
    updateFiltersSelected,
    clearFiltersSelected,
    insightsFilterShowSidebar,
    loadingMore,
    hasMore,
    selectedDateRange,
    previousDateRange,
    chosenPeriod,
    comparePeriod,
    mainRootdomain,
    onSelectRow,
  } = props;

  const [prevConfig, setPrevConfig] = useState([]);

  const [openSortingDropdown, setOpenSortingDropdown] = useState(false);

  const [isPrecentTrend, setIsPrecentTrend] = useState(false);

  const dropdownRef = useRef(null);

  const [decoratorType, setDecoratorType] = useState(
    CATALOG_TRACKER_VALUE_TYPE.price_diff
  );

  const getSelectedRootdomains = () => {
    var rootdomains = insightsFiltersCollection.find(
      (f) =>
        f.displayName === "Retailer" ||
        f.mbName === "product_kpis.rootdomain.keyword"
    );
    if (!rootdomains) {
      return viewDefinitions.filterDefinitions.find(
        (f) => f.displayName === "Retailer"
      ).values;
    }
    return rootdomains.values;
  };

  const rootdomains = getSelectedRootdomains() ?? [];
  const rootdomain = mainRootdomain ?? (rootdomains ? rootdomains[0] : "");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getMainDomainOrDefault = (insightsFiltersCollection, rootdomain) => {
    const domainFilter = insightsFiltersCollection.find(
      (f) =>
        f.displayName === "Retailer" ||
        f.mbName === "product_kpis.rootdomain.keyword"
    );
    if (!domainFilter) {
      return rootdomain;
    }
    if (domainFilter.values.find((f) => f === rootdomain)) {
      return rootdomain;
    }
    return domainFilter.values[0];
  };

  useEffect(() => {
    const mainDomainAfterFilter = getMainDomainOrDefault(
      insightsFiltersCollection,
      rootdomain
    );
    const config = {
      selectedDateRange: selectedDateRange,
      previousDateRange: previousDateRange,
      comparePeriod: comparePeriod,
      chosenPeriod: chosenPeriod,
      mainRootdomain: mainDomainAfterFilter,
      isPrecentTrend: isPrecentTrend,
      query: insightsFiltersCollection,
    };

    if (mainDomainAfterFilter) {
      if (!objectsEqual(config, prevConfig)) {
        setPrevConfig(config);
        props.getMatchedSkus({
          ...currentViewConfig.query,
          ...selectedDateRange,
          ...previousDateRange,
          mainRootdomain: mainDomainAfterFilter,
          settings: {
            ...currentViewConfig.query.settings,
            isPrecentTrend: isPrecentTrend,
          },
        });
      }
    }
  }, [
    insightsFiltersCollection,
    mainRootdomain,
    selectedDateRange,
    previousDateRange,
    comparePeriod,
    chosenPeriod,
  ]);

  const updateFilters = (filters) => {
    var mainRootdomainAfterFilter = getMainDomainOrDefault(filters, rootdomain);
    props.updateCurrentViewConfigQuery({
      productAttributes: filters,
      shouldProcessAttributes: false,
      mainRootdomain: mainRootdomainAfterFilter,
      ...selectedDateRange,
      ...previousDateRange,
      settings: {
        ...currentViewConfig.query.settings,
        isPrecentTrend: isPrecentTrend,
      },
    });
    props.setCurrentViewIsUpdated();
  };

  const getUpdatedFilters = (filters) => {
    const existingFilters = insightsFiltersCollection.filter(
      (collection) =>
        BASIC_FILTERS_DISPLAY_NAMES.includes(collection.displayName) ||
        BASIC_FILTERS_MB_NAMES.includes(collection.mbName)
    );
    return [...existingFilters, ...filters];
  };

  const setDecoratorTypeByName = (name) => {
    switch (name) {
      case "Average Price":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.price_diff);
        break;
      case "Ratings":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.ratings_and_avg);
        break;
      case "Average Rating":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.average);
        break;
      case "Out of Stock":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.oos);
        break;
      case "Price Index":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.index);
        break;
      case "On Promo":
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.promo);
        break;
      default:
        setDecoratorType(CATALOG_TRACKER_VALUE_TYPE.number);
    }
  };

  const onChangeTrendCallback = (config) => {
    setDecoratorTypeByName(config.trendName);
    setIsPrecentTrend(config.trendName === "Price Index");
    props.showSidebarFilter(false);
    const updatedFilters = getUpdatedFilters(
      config.overridingViewQuery.productAttributes
    );
    const sortingConfig = {
      sortOrder: config.overridingViewQuery.sortOrder,
      sortField: config.overridingViewQuery.sortField,
    };
    props.updateCurrentViewConfigQuery({
      productAttributes: updatedFilters,
      sortField: sortingConfig.sortField,
      sortOrder: sortingConfig.sortOrder,
      shouldProcessAttributes: false,
      ...selectedDateRange,
      ...previousDateRange,
      mainRootdomain: rootdomain,
      settings: {
        ...currentViewConfig.query.settings,
        isPrecentTrend: config.trendName === "Price Index",
        trendName: config.trendName,
        cellValueClassification:
          config.overridingViewQuery.productAttributes.length > 1
            ? CELL_VALUE_CLASSIFICATION.multiple
            : CELL_VALUE_CLASSIFICATION.single,
      },
    });
    props.setCurrentViewIsUpdated();
  };

  const fetchMoreData = () => {
    props.getMoreMatchedSkus(
      {
        ...currentViewConfig.query,
        ...selectedDateRange,
        ...previousDateRange,
        mainRootdomain: rootdomain,
        settings: {
          ...currentViewConfig.query.settings,
          isPrecentTrend: isPrecentTrend,
        },
      },
      matchesList.length
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setOpenSortingDropdown(false);
  };

  const table = (
    <CatalogTrackerTable
      matchesList={matchesList}
      valueType={decoratorType}
      isLoading={isLoading}
      rootdomains={rootdomains}
      mainRootdomain={rootdomain}
      onSelectRow={onSelectRow}
    />
  );

  const orderByDomainOptions = (
    <>
      {rootdomains?.map((item, index) => (
        <div
          className={styles.option}
          key={index}
          onClick={() => handleChooseSortByDomain(item)}
        >
          {item}
        </div>
      ))}
    </>
  );

  const handleDropdownClick = () => {
    setOpenSortingDropdown(!openSortingDropdown);
  };

  const handleChooseSortByDomain = (item) => {
    setOpenSortingDropdown(false);
    props.updateCurrentViewConfigQuery({
      ...currentViewConfig.query,
      mainRootdomain: item,
      settings: {
        ...currentViewConfig.query.settings,
        isPrecentTrend: isPrecentTrend,
      },
    });
  };

  const sortByDomainDropdown = (
    <div className={styles.wrapper}>
      <div ref={dropdownRef}>
        <div
          className={styles.sortByDomainDropDown}
          onClick={handleDropdownClick}
        >
          <div className={styles.sortByTitle}>
            <span className={styles.sortBy}>Sort Descending: </span>
            <span>{rootdomain}</span>
          </div>
          <img className={styles.chevron} src={chevron} />
        </div>
        {openSortingDropdown && (
          <div
            className={styles.optionsWrapper}
            style={{ width: dropdownRef.current?.offsetWidth }}
          >
            {orderByDomainOptions}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.controlsWrapper}>
        <MbBasicFilters
          filterDefinitions={viewDefinitions?.filterDefinitions}
          insightsFiltersCollection={insightsFiltersCollection}
          updateFilters={updateFilters}
          viewTypeId={CATALOG_TRACKER_VIEW_TYPE_DATA.id}
        />
        <div className={styles.trendsDomainWrapper}>
          <MarketTrendsButtons
            marketTrendsConfigs={marketTrendsConfigs}
            currentViewConfigQuery={currentViewConfig.query}
            viewName={currentViewConfig?.name}
            onChangeTrendCallback={onChangeTrendCallback}
            viewTypeId={CATALOG_TRACKER_VIEW_TYPE_DATA.id}
            clearFiltersSelected={clearFiltersSelected}
            updateFiltersSelected={updateFiltersSelected}
            insightsFilterShowSidebar={insightsFilterShowSidebar}
            mySkusSelected={false}
          />
          <div className={styles.sortByDomainWrapper}>
            {sortByDomainDropdown}
          </div>
        </div>
      </div>
      <InfiniteDataTable
        table={table}
        loading={isLoading}
        loadingMore={loadingMore}
        hasMore={hasMore}
        dataLength={matchesList?.length}
        fetchMoreData={fetchMoreData}
      />
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    insightsFiltersCollection:
      state.insights.insights.currentViewConfig.query.productAttributes,
    viewDefinitions: state.insights.insights.viewDefinitions,
    marketTrendsConfigs: state.insights.insights.marketTrendsConfigs,
    currentViewConfig: state.insights.insights.currentViewConfig,
    clearFiltersSelected: state.insights.insights.clearFiltersSelected,
    updateFiltersSelected: state.insights.insights.updateFiltersSelected,
    insightsFilterShowSidebar:
      state.insights.insights.insightsFilterShowSidebar,

    loadingMore: state.catalogTracker.loadingMore,
    hasMore: state.catalogTracker.hasMore,
    isLoading: state.catalogTracker.isLoading,
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    previousDateRange: state.insights.mbFilterRow.selectedPreviousDateRange,
    chosenPeriod: state.insights.mbFilterRow.chosenPeriod,
    comparePeriod: state.insights.mbFilterRow.comparePeriod,

    mainRootdomain:
      state.insights.insights.currentViewConfig.query.mainRootdomain,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getMatchedSkus,
      getMoreMatchedSkus,
      setCurrentViewIsUpdated,
      updateCurrentViewConfigQuery,
      showSidebarFilter,
      updateMainRootdomain,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogTrackerInfiniteTable);
