import React, { Component } from "react";
import Intercom from "react-intercom";

import { INTERCOM_API_KEY } from "../../config";

class IntercomWidget extends Component {
  render() {
    const { appUser } = this.props;

    const user = appUser
      ? {
          user_id: appUser.email,
          email: appUser.email,
          name: appUser.firstName + " " + appUser.lastName,
        }
      : undefined;

    return (
      <div className="app">
        <Intercom appID={INTERCOM_API_KEY} {...user} />
      </div>
    );
  }
}

export default IntercomWidget;
