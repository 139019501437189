import React from "react";

import { INDEX_COLORS } from "../../../catalog-tracker/constants";
import LineChartComponent from "../../../../components/charts/visx/line-chart-component";
import LineTooltipComponent from "../../../../components/charts/visx/tooltips/line-tooltip-component";
import {
  chartTickPriceFormat,
  priceDecorator,
} from "../../../../styles/cell-decorators";

const CatalogTrackerPriceHistoryChart = (props) => {
  const { priceHistory, isLoading } = props;

  const getChartData = () => {
    const data = [];
    const dates = Object.values(priceHistory).flatMap((retailer) =>
      Object.keys(retailer)
    );
    Array.from(new Set(dates)).forEach((date) => {
      const dateData = { date: parseFloat(date) };
      Object.keys(priceHistory).forEach((retailer) => {
        dateData[retailer] =
          priceHistory[retailer][date] != null
            ? priceHistory[retailer][date].toFixed(2)
            : null;
      });
      data.push(dateData);
    });
    return data;
  };

  const getDataKeys = () => {
    const dataKeys = {};
    const retailers = Object.keys(priceHistory);
    retailers.forEach((retailer, index) => {
      dataKeys[retailer] = {
        displayName: retailer,
        name: retailer,
        stroke: INDEX_COLORS[index],
      };
    });
    return dataKeys;
  };

  const lineChartXKey = "date";
  const chartData = getChartData();
  const dataKeys = getDataKeys();

  const keyFormatter = (k) => {
    return dataKeys[k] && dataKeys[k].displayName;
  };

  const tooltipCallback = () => {
    return LineTooltipComponent({
      section: "Price",
      valueDecorator: priceDecorator,
      entities: dataKeys,
      keyFormatter: keyFormatter,
      xKey: lineChartXKey,
    });
  };

  return (
    <LineChartComponent
      id={0}
      data={chartData}
      dataKeys={dataKeys}
      xKey={lineChartXKey}
      yTickFormat={chartTickPriceFormat}
      xNumTicks={Math.min(20, chartData.length)}
      tooltipCallback={tooltipCallback()}
      graphTitle={"Price History"}
      barChartData={[]}
      chartName={"Price History"}
      highlightKey={null}
    />
  );
};

export default CatalogTrackerPriceHistoryChart;
