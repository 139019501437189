import { IntercomAPI } from "react-intercom";

import { INTERCOM_API_KEY, MIXPANEL_PROJECT_TOKEN } from "../config";
import mixpanel from "mixpanel-browser";

mixpanel.init(MIXPANEL_PROJECT_TOKEN);

var user_id = "";
const EVENT_HTTP_ERROR = `HTTP_ERROR`;

export default class AnalyticsService {
  _setUser = (user) => {
    user_id = user;
    mixpanel.identify(user_id);
    mixpanel.alias(user_id, mixpanel.get_distinct_id());
    mixpanel.unregister("domain");
    mixpanel.people.set("$email", user_id);
    localStorage.setItem("user", user);
  };

  setDomain = (domain) => {
    mixpanel.people.set("domain", domain);
  };

  get setUser() {
    return this._setUser;
  }
  set setUser(value) {
    this._setUser = value;
  }

  verifyUser = () => {
    if (user_id === undefined || user_id === null || user_id === "") {
      user_id = localStorage.getItem("user");
    }
  };

  sendEvent = (event_name, data = {}, callback) => {
    this.verifyUser();
    let event_data = this._getBasicDataObject(event_name, data);

    // console.log('logging ' + event_name + ' to user ' + user_id + ' with data:');
    // console.log(data);

    mixpanel.track(event_name, data, undefined, callback);

    IntercomAPI("trackEvent", event_name, event_data);
    IntercomAPI("update");
  };

  sendHttpErrorEvent = (http_request_type, error_message, data = {}) => {
    this.verifyUser();
    data = {
      ...data,
      http_request_type: http_request_type,
      error_message: error_message,
    };
    let event_data = this._getBasicDataObject(EVENT_HTTP_ERROR, data);

    // console.log('logging http error ' + EVENT_HTTP_ERROR + ' to user ' + user_id + ' with data:');
    // console.log(data);

    IntercomAPI("trackEvent", EVENT_HTTP_ERROR, event_data);
    IntercomAPI("update");
  };

  _getBasicDataObject(event_name, data = {}) {
    var date = new Date();
    return {
      ...data,
      app_id: INTERCOM_API_KEY,
      user_id: user_id,
      email: user_id,
      event_name: event_name,
      timestamp: date.getTime(),
      date: date.toUTCString(),
    };
  }
}
