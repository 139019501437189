// NEW ANALYTICS
export const EVENT_LOGIN_SUCCESS = `insights_login`;
export const EVENT_SKU_TRACKER = `insights_sku_tracker`;
export const EVENT_MARKET_SHARE = `insights_market_share`;
export const EVENT_ACCOUNT_PERFORMANCE = `insights_account_performance`;
export const EVENT_CATEGORY_INSIGHTS = `insights_category_insights`;
export const EVENT_RANKING_EVENTS = `insights_ranking_events`;
export const EVENT_MODULE_LOAD = `insights_module_load`;
export const EVENT_LOAD_VIEW = `insights_load_view`;
export const EVENT_SAVE_VIEW = `insights_save_view`;
export const EVENT_EXPORT_PDF = `insights_export_pdf`;
export const EVENT_FEATURE_FEEDBACK = `insights_feature_feedback`;
export const EVENT_TEXT_COPIED = `insights_text_copied`;
export const EVENT_INSIGHTS_AI_OPENED = `insights_ai_opened`;
export const EVENT_MARKET_TREND_SELECTED = `quick_sort_selected`;
export const EVENT_PIVOT_SELECTED = `pivot_selected`;
export const EVENT_CHART_INTERACTION = `chart_interaction`;
export const EVENT_CALENDAR_USED = `calendar_used`;
export const EVENT_COMPARE_PERIOD_SELECTED = `compare_period_selected`;
export const EVENT_TOGGLED_MY_SKUS = `show_my_skus_only_toggled`;
export const EVENT_PAGE_TAB_SELECTED = `page_tab_selected`;
export const EVENT_MARKET_SHARE_ANALYSIS_CLICKED = `market_share_analysis_clicked`;
export const EVENT_MARKET_SHARE_ANALYSIS_DATA = `market_share_analysis_data`;
export const EVENT_FILTER_FROM_GRAPH = `filter_from_graph`;
export const EVENT_FILTER_APPLIED = `insights_filter_applied`;
export const EVENT_SUPPORT_MESSAGE = `insights_support_message`;
export const EVENT_TRIGGER_CREATED = `insights_trigger_created`;
export const EVENT_TRIGGER_SELECTED = `insights_trigger_selected`;
export const EVENT_ALERTS_TABLE_OPTION = `insights_alerts_table_option`;
export const EVENT_SKU_VIEW_MODE = `insights_sku_view_mode`;

// CHART INTERACTIONS
export const CHART_COMPARE_PERIOD_TOGGLED = `chart_compare_period_toggled`;
export const CHART_TYPE_SELECTED = `chart_type_selected`;

// Login
export const EVENT_LOGIN_REQUEST = `LOGIN_REQUEST`;
export const EVENT_LOGIN_FAILED = `LOGIN_FAILED`;

// Insights
export const EVENT_INSIGHT_REQUEST = `INSIGHT_REQUEST`;
export const EVENT_INSIGHT_SUCCESS = `INSIGHT_SUCCESS`;
export const EVENT_INSIGHT_FAILED = `INSIGHT_FAILED`;

export const EVENT_INSIGHT_SKUS_REQUEST = `INSIGHT_SKUS_REQUEST`;
export const EVENT_INSIGHT_SKUS_SUCCESS = `INSIGHT_SKUS_SUCCESS`;
export const EVENT_INSIGHT_SKUS_FAILED = `INSIGHT_SKUS_FAILED`;

export const EVENT_INSIGHT_MORE_SKUS_REQUEST = `INSIGHT_MORE_SKUS_REQUEST`;
export const EVENT_INSIGHT_MORE_SKUS_SUCCESS = `INSIGHT_MORE_SKUS_SUCCESS`;
export const EVENT_INSIGHT_MORE_SKUS_FAILED = `INSIGHT_MORE_SKUS_FAILED`;

export const EVENT_INSIGHT_COMPETITOR_CHANGE = `INSIGHT_COMPETITOR_CHANGE`;
export const EVENT_INSIGHT_SORT_CHANGE = `INSIGHT_SORT_CHANGE`;
export const EVENT_INSIGHT_CATEGORY_L1_SELECT = `INSIGHT_CATEGORY_L1_SELECT`;
export const EVENT_INSIGHT_CATEGORY_L2_SELECT = `INSIGHT_CATEGORY_L2_SELECT`;
export const EVENT_INSIGHT_SKU_SELECT = `INSIGHT_SKU_SELECT`;

export const EVENT_INSIGHT_NO_MORE_SKUS = `INSIGHT_NO_MORE_SKUS`;

// Top Skus
export const EVENT_TOP_SKUS_REQUEST = `TOP_SKUS_REQUEST`;
export const EVENT_TOP_SKUS_SUCCESS = `TOP_SKUS_SUCCESS`;
export const EVENT_TOP_SKUS_FAILED = `TOP_SKUS_FAILED`;

export const EVENT_TOP_SKUS_MORE_SKUS_REQUEST = `TOP_SKUS_MORE_SKUS_REQUEST`;
export const EVENT_TOP_SKUS_MORE_SKUS_SUCCESS = `TOP_SKUS_MORE_SKUS_SUCCESS`;
export const EVENT_TOP_SKUS_MORE_SKUS_FAILED = `TOP_SKUS_MORE_SKUS_FAILED`;

export const EVENT_TOP_SKUS_BRANDS_REQUEST = `TOP_SKUS_BRANDS_REQUEST`;
export const EVENT_TOP_SKUS_BRANDS_SUCCESS = `TOP_SKUS_BRANDS_SUCCESS`;
export const EVENT_TOP_SKUS_BRANDS_FAILED = `TOP_SKUS_BRANDS_FAILED`;

export const EVENT_TOP_SKUS_COMPETITOR_CHANGE = `TOP_SKUS_COMPETITOR_CHANGE`;
export const EVENT_TOP_SKUS_CATEGORY_L1_SELECT = `TOP_SKUS_CATEGORY_L1_SELECT`;

export const EVENT_TOP_SKUS_QUERY_CHANGE = `TOP_SKUS_QUERY_CHANGE`;
export const EVENT_TOP_SKUS_BRAND_CHANGE = `TOP_SKUS_BRAND_CHANGE`;
export const EVENT_TOP_SKUS_SORT_CHANGE = `TOP_SKUS_SORT_CHANGE`;
export const EVENT_TOP_SKUS_PRICE_FILTER_CHANGE = `TOP_SKUS_PRICE_FILTER_CHANGE`;
export const EVENT_TOP_SKUS_PRICE_RANGE_CHANGE = `TOP_SKUS_PRICE_RANGE_CHANGE`;
export const EVENT_TOP_SKUS_SKU_SELECT = `TOP_SKUS_SKU_SELECT`;

export const EVENT_TOP_SKUS_NO_RESULTS_SHOWN = `NO_RESULTS_SHOWN`;

// Product Groups
export const EVENT_PRODUCT_GROUPS_LIST_REQUEST = `PRODUCT_GROUPS_LIST_REQUEST`;
export const EVENT_PRODUCT_GROUPS_LIST_SUCCESS = `PRODUCT_GROUPS_LIST_SUCCESS`;
export const EVENT_PRODUCT_GROUPS_LIST_FAILED = `PRODUCT_GROUPS_LIST_FAILED`;
export const EVENT_PRODUCT_GROUPS_LIST_NO_RESULTS_SHOWN = `PRODUCT_GROUPS_LIST_NO_RESULTS_SHOWN`;

export const EVENT_PRODUCT_TYPES_DEFINITIONS_REQUEST = `PRODUCT_TYPES_DEFINITIONS_REQUEST`;
export const EVENT_PRODUCT_TYPES_DEFINITIONS_SUCCESS = `PRODUCT_TYPES_DEFINITIONS_SUCCESS`;
export const EVENT_PRODUCT_TYPES_DEFINITIONS_FAILED = `PRODUCT_TYPES_DEFINITIONS_FAILED`;
export const EVENT_PRODUCT_TYPES_DEFINITIONS_NO_RESULTS_SHOWN = `PRODUCT_TYPES_DEFINITIONS_NO_RESULTS_SHOWN`;

export const EVENT_PRODUCT_TYPES_ATTRIBUTES_REQUEST = `PRODUCT_TYPES_ATTRIBUTES_REQUEST`;
export const EVENT_PRODUCT_TYPES_ATTRIBUTES_SUCCESS = `PRODUCT_TYPES_ATTRIBUTES_SUCCESS`;
export const EVENT_PRODUCT_TYPES_ATTRIBUTES_FAILED = `PRODUCT_TYPES_ATTRIBUTES_FAILED`;

export const EVENT_UNSUPPORTED_MODULE_SELECT = `UNSUPPORTED_MODULE_SELECT`;
