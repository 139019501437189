import React from "react";

import styles from "./mb-basic-filters.scss";
import MultipleSelectBasicFilter from "./multiple-select-basic-filter/multiple-select-basic-filter";
import { getEnumDisplayNameFromDefinition } from "../../utils/other/enumUtils";

export default function MbBasicFilters(props) {
  const {
    filterDefinitions,
    insightsFiltersCollection,
    updateFilters,
    viewTypeId,
  } = props;

  const basicFilters =
    filterDefinitions?.filter((item) => item.basicFilterConstraints) ?? [];

  const getOptions = (values, displayName) => {
    if (values?.length > 0) {
      if (displayName == "Category") {
        return values
          .sort((a, b) => a.split(">")[2]?.localeCompare(b.split(">")[2]))
          .map((x, i) => ({
            id: i,
            name: x.split(">")[2] ?? x,
          }));
      }
      return values.sort().map((x, i) => ({
        id: i,
        name: x,
      }));
    }
    return [];
  };

  const getCollectionSelectedItems = (displayName, mbName) => {
    const filters = insightsFiltersCollection
      .filter(
        (item) => item.displayName === displayName || item.mbName === mbName
      )
      .map((item) => item.values);
    return filters;
  };

  const getEnumSelectedItems = (displayName, mbName) => {
    const filters = getCollectionSelectedItems(displayName, mbName);
    const filterDefinition = filterDefinitions.find((x) => x.mbName === mbName);
    return filters && filters.length > 0
      ? filters[0].map((x) =>
          getEnumDisplayNameFromDefinition(filterDefinition, x)
        )
      : [];
  };

  const getComponentFilter = (filter, index) => {
    switch (filter.type) {
      case "Collection":
      case "Enum":
        return (
          <MultipleSelectBasicFilter
            id={index}
            key={index}
            title={filter.displayName}
            options={getOptions(filter.values, filter.displayName)}
            selectedFilters={
              filter.type === "Collection"
                ? getCollectionSelectedItems(filter.displayName, filter.mbName)
                : getEnumSelectedItems(filter.displayName, filter.mbName)
            }
            filterDefinition={filter}
            updateFilters={updateFilters}
            insightsFiltersCollection={insightsFiltersCollection}
            viewTypeId={viewTypeId}
          />
        );
    }
  };
  const filters = (
    <>{basicFilters.map((item, index) => getComponentFilter(item, index))}</>
  );

  const topFilters = (
    <>
      {basicFilters
        .filter((x) => x.uiPosition === "top")
        .map((item, index) => getComponentFilter(item, index))}
    </>
  );
  const bottomFilters = (
    <>
      {basicFilters
        .filter((x) => x.uiPosition !== "top")
        .map((item, index) => getComponentFilter(item, index))}
    </>
  );

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.basicFiltersWrapper}>{topFilters}</div>
      <div className={styles.basicFiltersWrapper}>{bottomFilters}</div>
    </div>
  );
}
