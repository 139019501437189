import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./catalog-tracker-sku-details-body.scss";
import CatalogTrackerSkuDetailsHeader from "../catalog-tracker-sku-details-header/catalog-tracker-sku-details-header";
import CatalogTrackerPriceHistoryChart from "../catalog-tracker-price-history-chart/catalog-tracker-price-history-chart";
import CatalogTrackerAvailabilityHistory from "../catalog-tracker-availability-history/catalog-tracker-availability-history";
import CatalogTrackerDomainsTable from "../catalog-tracker-domains-table/catalog-tracker-domains-table";

import {
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../catalog-tracker-sku-details/constants";

import { fetchSkuDetailsData } from "../../actions";

const CatalogTrackerSkuDetailsBody = (props) => {
  const {
    selectedMatchingId,
    forceLoading,
    currentViewConfigQuery,
    productAttributes,
    selectedDateRange,
    match,
    domainsTableData,
    priceHistory,
    availabilityHistory,
    fetchSkuDetailsData,
    status,
  } = props;

  const getAllData = () => {
    const requestBody = {
      ...currentViewConfigQuery,
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate,
    };
    fetchSkuDetailsData(selectedMatchingId, requestBody);
  };

  useEffect(() => {
    getAllData();
  }, [selectedMatchingId, selectedDateRange]);

  const header = (
    <CatalogTrackerSkuDetailsHeader
      match={match}
      isLoading={status !== STATUS_SUCCESS}
    />
  );
  const domainsTable = (
    <CatalogTrackerDomainsTable
      domainsTableData={domainsTableData}
      skuUrls={match?.skuUrls}
      isLoading={status !== STATUS_SUCCESS}
    />
  );
  const priceHistoryChart = (
    <CatalogTrackerPriceHistoryChart
      priceHistory={priceHistory}
      isLoading={status !== STATUS_SUCCESS}
    />
  );
  const availabilityHistoryChart = (
    <CatalogTrackerAvailabilityHistory
      availabilityHistory={availabilityHistory}
      isLoading={status !== STATUS_SUCCESS}
    />
  );

  return (
    <div className={styles.catalogTrackerSkuDetailsWrapper}>
      <div>{header}</div>
      <div>{domainsTable}</div>
      <div>{priceHistoryChart}</div>
      {/* <div>{availabilityHistoryChart}</div> */}
    </div>
  );
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSkuDetailsData,
    },
    dispatch
  );

export const mapStateToProps = (state) => {
  return {
    selectedDateRange:
      state.catalogTrackerSkuDetails.insights.mbFilterRow.selectedDateRange,
    currentViewConfigQuery:
      state.catalogTrackerSkuDetails.insights.insights.currentViewConfig.query,
    productAttributes:
      state.catalogTrackerSkuDetails.insights.insights.currentViewConfig.query
        .productAttributes,
    selectedMatchingId:
      state.catalogTrackerSkuDetails.catalogTrackerSkuDetails
        .selectedMatchingId,
    match: state.catalogTrackerSkuDetails.catalogTrackerSkuDetails.match,
    domainsTableData:
      state.catalogTrackerSkuDetails.catalogTrackerSkuDetails.domainsTableData,
    priceHistory:
      state.catalogTrackerSkuDetails.catalogTrackerSkuDetails.priceHistory,
    availabilityHistory:
      state.catalogTrackerSkuDetails.catalogTrackerSkuDetails
        .availabilityHistory,
    status: state.catalogTrackerSkuDetails.catalogTrackerSkuDetails.status,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogTrackerSkuDetailsBody);
