import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_SUCCESS,
  FETCH_SKU_DETAILS_DATA_CANCEL,
  FETCH_SKU_DETAILS_DATA_FAILURE,
  FETCH_SKU_DETAILS_DATA_REQUEST,
  FETCH_SKU_DETAILS_DATA_SUCCESS,
  APPLY_INITIAL_STATE,
  SET_SELECTED_MATCHING_ID,
} from "./constants";

const initialState = {
  status: STATUS_IDLE,
  selectedMatchingId: null,
  match: {},
  domainsTableData: {},
  priceHistory: {},
  availabilityHistory: {},
};

const catalogTrackerSkuDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case SET_SELECTED_MATCHING_ID: {
      const data = action.payload;

      return {
        ...state,
        selectedMatchingId: data,
      };
    }

    case FETCH_SKU_DETAILS_DATA_CANCEL: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case FETCH_SKU_DETAILS_DATA_FAILURE: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }

    case FETCH_SKU_DETAILS_DATA_REQUEST: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }

    case FETCH_SKU_DETAILS_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        status: STATUS_SUCCESS,
        match: data.match,
        domainsTableData: data.domainsTableData,
        priceHistory: data.priceHistory,
        availabilityHistory: data.availabilityHistory,
      };
    }
  }
  return state;
};

export default catalogTrackerSkuDetailsReducer;
