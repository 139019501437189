import {
  SET_CURRENT_VIEW_IS_UPDATED,
  SET_INITIAL_VIEW_LOADED,
  SET_CURRENT_VIEW_CONFIG,
  UPDATE_CURRENT_VIEW_CONFIG,
  UPDATE_CURRENT_VIEW_CONFIG_QUERY,
  FETCH_VIEWS_DEFINITIONS_REQUEST,
  FETCH_VIEWS_DEFINITIONS_FAILURE,
  FETCH_VIEWS_DEFINITIONS_SUCCESS,
  FETCH_VIEWS_DEFINITIONS_CANCEL,
  FETCH_FILTER_DEFINITIONS_REQUEST,
  FETCH_FILTER_DEFINITIONS_FAILURE,
  FETCH_FILTER_DEFINITIONS_SUCCESS,
  FETCH_FILTER_DEFINITIONS_CANCEL,
  SAVE_INSIGHTS_VIEW_CONFIG_REQUEST,
  SAVE_INSIGHTS_VIEW_CONFIG_FAILURE,
  SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  SAVE_INSIGHTS_VIEW_CONFIG_CANCEL,
  FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST,
  FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE,
  FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS,
  FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL,
  UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST,
  UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE,
  UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL,
  DELETE_INSIGHTS_VIEW_CONFIG_REQUEST,
  DELETE_INSIGHTS_VIEW_CONFIG_FAILURE,
  DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  DELETE_INSIGHTS_VIEW_CONFIG_CANCEL,
  EXPORT_INSIGHTS_VIEW_REQUEST,
  EXPORT_INSIGHTS_VIEW_FAILURE,
  EXPORT_INSIGHTS_VIEW_SUCCESS,
  EXPORT_INSIGHTS_VIEW_CANCEL,
  REMOVE_EXPORTED_INSIGHTS_VIEW_DATA,
} from "./constants";

import { isFunction } from "../../../utils/other/utilities";
import { analyticsSaveView } from "../../shared/analytics/actions";

export const setInitialViewLoaded = () => ({
  type: SET_INITIAL_VIEW_LOADED,
});

export const setCurrentViewIsUpdated = () => ({
  type: SET_CURRENT_VIEW_IS_UPDATED,
});

export const setCurrentViewConfig = (data) => ({
  type: SET_CURRENT_VIEW_CONFIG,
  payload: { data },
});

export const updateCurrentViewConfig = (data) => ({
  type: UPDATE_CURRENT_VIEW_CONFIG,
  payload: data,
});

export const updateCurrentViewConfigQuery = (data) => ({
  type: UPDATE_CURRENT_VIEW_CONFIG_QUERY,
  payload: { data },
});

///////////
export const fetchInsightsViewsDefinitionsRequest = () => ({
  type: FETCH_VIEWS_DEFINITIONS_REQUEST,
});

export const fetchInsightsViewsDefinitionsFailure = () => ({
  type: FETCH_VIEWS_DEFINITIONS_FAILURE,
});

export const fetchInsightsViewsDefinitionsSuccess = (data) => ({
  type: FETCH_VIEWS_DEFINITIONS_SUCCESS,
  payload: data,
});

export const fetchInsightsViewsDefinitionsCancel = () => ({
  type: FETCH_VIEWS_DEFINITIONS_CANCEL,
});

export const fetchInsightsFilterDefinitionsRequest = () => ({
  type: FETCH_FILTER_DEFINITIONS_REQUEST,
});

export const fetchInsightsFilterDefinitionsFailure = () => ({
  type: FETCH_FILTER_DEFINITIONS_FAILURE,
});

export const fetchInsightsFilterDefinitionsSuccess = (data) => ({
  type: FETCH_FILTER_DEFINITIONS_SUCCESS,
  payload: data,
});

export const fetchInsightsFilterDefinitionsCancel = () => ({
  type: FETCH_FILTER_DEFINITIONS_CANCEL,
});

export const fetchInsightsViewsDefinitions = (
  apiProvider,
  viewType,
  dateRange,
  callback
) => (dispatch) => {
  dispatch(fetchInsightsViewsDefinitionsRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .fetchInsightsViewsDefinitions(viewType, dateRange)
      .then((res) => {
        dispatch(fetchInsightsViewsDefinitionsSuccess(res));
        dispatch(
          fetchInsightsFilterDefinitions(
            apiProvider,
            viewType,
            dateRange,
            callback
          )
        );
        if (isFunction(callback)) {
          callback(res);
        }
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(fetchInsightsViewsDefinitionsCancel());
        } else {
          dispatch(fetchInsightsViewsDefinitionsFailure());
        }
        reject(err);
      });
  });
};

export const fetchInsightsFilterDefinitions = (
  apiProvider,
  viewType,
  dateRange,
  callback
) => (dispatch) => {
  dispatch(fetchInsightsFilterDefinitionsRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .fetchInsightsFilterDefinitions(viewType, dateRange)
      .then((res) => {
        dispatch(fetchInsightsFilterDefinitionsSuccess(res));
        if (isFunction(callback)) {
          callback(res);
        }
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(fetchInsightsFilterDefinitionsCancel());
        } else {
          dispatch(fetchInsightsFilterDefinitionsFailure());
        }
        reject(err);
      });
  });
};

////////////////

export const saveInsightsViewConfigRequest = () => ({
  type: SAVE_INSIGHTS_VIEW_CONFIG_REQUEST,
});

export const saveInsightsViewConfigFailure = () => ({
  type: SAVE_INSIGHTS_VIEW_CONFIG_FAILURE,
});

export const saveInsightsViewConfigSuccess = (data) => ({
  type: SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  payload: data,
});

export const saveInsightsViewConfigCancel = () => ({
  type: SAVE_INSIGHTS_VIEW_CONFIG_CANCEL,
});

export const saveInsightsViewConfig = (
  apiProvider,
  viewConfigName,
  viewConfigData,
  viewTypeData,
  startDate = null,
  endDate = null,
  rolling = null,
  previousDates = {},
  comparePeriod = null,
  emails,
  notificationFrequency
) => (dispatch) => {
  dispatch(saveInsightsViewConfigRequest());
  viewConfigData.startDate = startDate;
  viewConfigData.endDate = endDate;
  viewConfigData.rolling = rolling;
  viewConfigData.previousStartDate = previousDates.previousStartDate;
  viewConfigData.previousEndDate = previousDates.previousEndDate;
  viewConfigData.comparePeriod = comparePeriod;

  return new Promise((resolve, reject) => {
    apiProvider
      .saveInsightsViewConfig(
        viewConfigName,
        {
          query: viewConfigData,
          emails,
          notificationFrequency,
        },
        viewTypeData.id
      )
      .then((res) => {
        dispatch(saveInsightsViewConfigSuccess(res));
        analyticsSaveView(viewTypeData.id, viewConfigName);
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(saveInsightsViewConfigCancel());
        } else {
          dispatch(saveInsightsViewConfigFailure());
        }
        reject(err);
      });
  });
};

///////////
export const fetchInsightsViewsConfigsRequest = () => ({
  type: FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST,
});

export const fetchInsightsViewsConfigsFailure = () => ({
  type: FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE,
});

export const fetchInsightsViewsConfigsSuccess = (data) => ({
  type: FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS,
  payload: data,
});

export const fetchInsightsViewsConfigsCancel = () => ({
  type: FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL,
});

export const fetchInsightsViewsConfigs = (apiProvider, viewType) => (
  dispatch
) => {
  dispatch(fetchInsightsViewsConfigsRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .fetchInsightsViewsConfigs(viewType)
      .then((res) => {
        dispatch(fetchInsightsViewsConfigsSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(fetchInsightsViewsConfigsCancel());
        } else {
          dispatch(fetchInsightsViewsConfigsFailure());
        }
        reject(err);
      });
  });
};

///////////
export const updateInsightsViewConfigRequest = () => ({
  type: UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST,
});

export const updateInsightsViewConfigFailure = () => ({
  type: UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE,
});

export const updateiInsightsVewConfigSuccess = (data) => ({
  type: UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  payload: data,
});

export const updateInsightsViewConfigCancel = () => ({
  type: UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL,
});

export const updateInsightsViewConfig = (apiProvider, viewConfig) => (
  dispatch
) => {
  const { id } = viewConfig;

  dispatch(updateInsightsViewConfigRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .updateInsightsViewConfig(id, viewConfig)
      .then((res) => {
        dispatch(updateiInsightsVewConfigSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(updateInsightsViewConfigCancel());
        } else {
          dispatch(updateInsightsViewConfigFailure());
        }
        reject(err);
      });
  });
};

export const updateFavoriteInsightsViewConfig = (apiProvider, id) => (
  dispatch
) => {
  dispatch(updateInsightsViewConfigRequest());
  apiProvider
    .updateFavoriteInsightViewConfig(id)
    .then((res) => {
      dispatch(updateiInsightsVewConfigSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(updateInsightsViewConfigCancel());
      } else {
        dispatch(updateInsightsViewConfigFailure());
      }
    });
};

export const updateInsightsViewConfig2 = (
  apiProvider,
  viewConfig,
  startDate = null,
  endDate = null,
  rolling = null,
  previousDates = null,
  comparePeriod = null
) => (dispatch) => {
  const { id } = viewConfig;
  viewConfig.query.startDate = startDate;
  viewConfig.query.endDate = endDate;
  viewConfig.query.rolling = rolling;
  viewConfig.query.previousStartDate = previousDates.previousStartDate;
  viewConfig.query.previousEndDate = previousDates.previousEndDate;
  viewConfig.query.comparePeriod = comparePeriod;

  dispatch(updateInsightsViewConfigRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .updateInsightsViewConfig2(id, viewConfig)
      .then((res) => {
        dispatch(updateiInsightsVewConfigSuccess(res));
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(updateInsightsViewConfigCancel());
        } else {
          dispatch(updateInsightsViewConfigFailure());
        }
        reject(err);
      });
  });
};

//////////////////////

export const deleteInsightsViewConfigRequest = () => ({
  type: DELETE_INSIGHTS_VIEW_CONFIG_REQUEST,
});

export const deleteInsightsViewConfigFailure = () => ({
  type: DELETE_INSIGHTS_VIEW_CONFIG_FAILURE,
});

export const deleteInsightsViewConfigSuccess = (data) => ({
  type: DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS,
  payload: data,
});

export const deleteInsightsViewConfigCancel = () => ({
  type: DELETE_INSIGHTS_VIEW_CONFIG_CANCEL,
});

export const deleteInsightsViewConfig = (apiProvider, id) => (dispatch) => {
  dispatch(deleteInsightsViewConfigRequest());

  return new Promise((resolve, reject) => {
    apiProvider
      .deleteInsightsViewConfig(id)
      .then((res) => {
        dispatch(deleteInsightsViewConfigSuccess({ data: { id } } /*res*/)); //ToDo Response should return ID !!!! need fix API
        resolve(res);
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(deleteInsightsViewConfigCancel());
        } else {
          dispatch(deleteInsightsViewConfigFailure());
        }
        reject(err);
      });
  });
};

//////////////////////

export const exportInsightsViewRequest = () => ({
  type: EXPORT_INSIGHTS_VIEW_REQUEST,
});

export const exportInsightsViewFailure = () => ({
  type: EXPORT_INSIGHTS_VIEW_FAILURE,
});

export const exportInsightsViewSuccess = (data) => ({
  type: EXPORT_INSIGHTS_VIEW_SUCCESS,
  payload: data,
});

export const exportInsightsViewCancel = () => ({
  type: EXPORT_INSIGHTS_VIEW_CANCEL,
});

export const exportInsightsView = (
  apiProvider,
  viewConfigQuery,
  viewType,
  dates = null,
  settings = null,
  previousDates = null
) => (dispatch) => {
  console.error("1");
  dispatch(exportInsightsViewRequest());
  console.error("2");
  apiProvider
    .exportInsightsView(
      viewConfigQuery,
      viewType,
      dates,
      settings,
      previousDates
    )
    .then((res) => {
      console.error("3");
      dispatch(exportInsightsViewSuccess(res));
    })
    .catch((err) => {
      console.error(err);
      if (err.message === "cancel") {
        dispatch(exportInsightsViewCancel());
      } else {
        dispatch(exportInsightsViewFailure());
      }
    });
};

export const removeExportedInsightsViewData = () => ({
  type: REMOVE_EXPORTED_INSIGHTS_VIEW_DATA,
});
