import moment from "moment";
import DatePickerService from "../../services/datePickerService";
import { getDateRangeInfo } from "../../utils/other/dates";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../insights-board/constants";
import { MARKET_SHARE_VIEW_TYPE_DATA } from "../market-share/constants";
import { PRODUCT_360_VIEW_TYPE_DATA } from "../product-360/constants";
import {
  FREQUENCY_PRESETS,
  SKU_TRACKER_VIEW_TYPE_DATA,
} from "../skus/constants";
import { COMPARE_PERIODS } from "../../components/mb-compare-period/constants";
import { TopSkusChosenPeriodToDateConfig } from "../skus/utils";
import { RANKING_EVENTS_VIEW_TYPE_DATA } from "../ranking-events/constants";
import { CATALOG_TRACKER_VIEW_TYPE_DATA } from "../catalog-tracker/constants";
import { CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA } from "../catalog-tracker-sku-details/constants";
const datePickerService = new DatePickerService();

const DEFAULTS = {
  [RANKING_EVENTS_VIEW_TYPE_DATA.id]: {
    frequency: "Last 7 days",
  },
  [SKU_TRACKER_VIEW_TYPE_DATA.id]: {
    frequency: "Last 30 days",
  },
  [MARKET_SHARE_VIEW_TYPE_DATA.id]: {
    rolling: 2,
  },
  [ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA.id]: {
    rolling: 2,
  },
  [PRODUCT_360_VIEW_TYPE_DATA.id]: {
    rolling: 2,
  },
  [CATALOG_TRACKER_VIEW_TYPE_DATA.id]: {
    rolling: 2,
  },
};

export default class SharedFunctions {
  getComparePeriodString = (comparePeriodInt) => {
    switch (parseInt(comparePeriodInt)) {
      case 2:
        return COMPARE_PERIODS.previousYear;
      default:
        return COMPARE_PERIODS.previousPeriod;
    }
  };

  handleQueryDateConfig = (
    dateConfig = {},
    viewTypeDataId,
    lastAvailableDate,
    setPreviousSelectedDateRange,
    setComparePeriod,
    setSelectedDateRange,
    setChosenPeriod
  ) => {
    let dateRange = {};
    let defaultValues = DEFAULTS[viewTypeDataId];
    switch (viewTypeDataId) {
      case RANKING_EVENTS_VIEW_TYPE_DATA.id:
      case SKU_TRACKER_VIEW_TYPE_DATA.id:
        if (dateConfig.queryFrequency === undefined) {
          dateConfig.queryFrequency =
            FREQUENCY_PRESETS[defaultValues.frequency];
        }
        // get chosenPeriod from queryFrequency
        const chosenPeriod = Object.entries(FREQUENCY_PRESETS).find(
          ([k, v]) => v === dateConfig.queryFrequency
        )?.[0];
        // get dates from chosenPeriod
        dateRange = TopSkusChosenPeriodToDateConfig(
          chosenPeriod,
          moment(lastAvailableDate)
        );
        dateRange.chosenPeriod = chosenPeriod;
        dateConfig.comparePeriod = dateRange.comparePeriod;
        break;
      case ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA.id:
      case MARKET_SHARE_VIEW_TYPE_DATA.id:
      case PRODUCT_360_VIEW_TYPE_DATA.id:
        dateRange = {
          startDate: dateConfig.startDate,
          endDate: dateConfig.endDate,
          rolling: parseInt(dateConfig.rolling),
        };
        if (!dateConfig.startDate || !dateConfig.endDate)
          dateRange.rolling = defaultValues.rolling;
        if (!Object.values(COMPARE_PERIODS).includes(dateConfig.comparePeriod))
          dateConfig.comparePeriod = this.getComparePeriodString(
            dateConfig.comparePeriod
          );
        break;
      case CATALOG_TRACKER_VIEW_TYPE_DATA.id:
      case CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA.id:
        dateRange = {
          startDate: dateConfig?.startDate || lastAvailableDate,
          endDate: dateConfig?.endDate || lastAvailableDate,
          rolling: dateConfig?.rolling || 4,
        };
        if (!Object.values(COMPARE_PERIODS).includes(dateConfig.comparePeriod))
          dateConfig.comparePeriod = this.getComparePeriodString(
            dateConfig.comparePeriod
          );
        break;
      default:
        return;
    }
    if (dateRange.rolling !== 0) {
      const rollingDateRange = getDateRangeInfo(
        lastAvailableDate,
        parseInt(dateRange.rolling),
        dateRange,
        "weekly"
      );
      dateRange.startDate = rollingDateRange.startDate;
      dateRange.endDate = rollingDateRange.endDate;
      dateRange.chosenPeriod = rollingDateRange.chosenPeriod;
    }
    const previousDates = datePickerService.getPreviousDates(
      dateRange,
      dateConfig.comparePeriod
    );

    setPreviousSelectedDateRange(previousDates);
    setComparePeriod(dateConfig.comparePeriod);
    setSelectedDateRange(dateRange);
    setChosenPeriod(dateRange.chosenPeriod);
  };
}
