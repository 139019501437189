import React, { useState, useEffect, useRef } from "react";
import FilterAttributesDropdown from "../../../components/filter-attributes-dropdown/filter-attributes-dropdown";
import { ConvertFilterSidebarToInsightsFilter } from "../../mb-filter-sidebar/filter-conversions";
import styles from "./multiple-select-basic-filter.scss";
import dropdownStyles from "./multiple-select-dropdown.scss";
import { analyticsFiltersApplied } from "../../../containers/shared/analytics/actions";

export default function MultipleSelectBasicFilter(props) {
  const {
    title,
    options,
    selectedFilters,
    filterDefinition,
    updateFilters,
    insightsFiltersCollection,
    viewTypeId,
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [displayTitle, setDisplayTitle] = useState("");

  const [openDropdown, setOpenDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const selectedIds = getSelectedIdsByFilter();
    setSelectedItems(selectedIds);
    setDisplayTitle(
      getDisplayTitle(options.filter((item) => selectedIds.includes(item.id)))
    );
  }, [selectedFilters]);

  const getSelectedIdsByFilter = () => {
    if (selectedFilters.length < 1) {
      return [];
    }
    switch (title) {
      case "Category":
        return options
          .filter((option) => {
            const lastFilter = selectedFilters.map(
              (filter) => filter[0].split(">")[2]
            );
            return lastFilter.includes(option.name);
          })
          .map((option) => option.id);
      case "Retailer":
      case "Brand":
      default:
        return options
          .filter((option) =>
            selectedFilters.some((filter) => filter.includes(option.name))
          )
          .map((option) => option.id);
    }
    return [];
  };

  const getSelectedFilters = (values) => {
    let selectedProperties = null;
    switch (title) {
      case "Category":
        selectedProperties = filterDefinition.values.filter((item) => {
          return values.some((value) => value.name === item.split(">")[2]);
        });
        return selectedProperties.map((property, id) => ({
          ...filterDefinition,
          childFilters: [],
          selectedProperties: property,
          selectedCondition: 0,
          id: id,
        }));
      case "Retailer":
      case "Brand":
      default:
        selectedProperties = filterDefinition.values.filter((item) => {
          return values.some((value) => value.name === item);
        });
        return selectedProperties.length > 0
          ? [
              {
                ...filterDefinition,
                childFilters: [],
                selectedProperties: selectedProperties,
                selectedCondition: 0,
                id: 0,
              },
            ]
          : [];
    }
  };

  const onChangeSelectedItems = (values) => {
    // Get filters in order to reuse functions from filter conversions.
    const filters = getSelectedFilters(values);
    const insightsFilters = ConvertFilterSidebarToInsightsFilter(filters);
    const mergedFilters = insightsFilters.concat(
      insightsFiltersCollection.filter(
        (collection) => collection.displayName !== title
      )
    );
    updateFilters(mergedFilters);
    analyticsFiltersApplied(viewTypeId, filters);
  };

  const formatValue = (value) => {
    return value != null && value.length > 32
      ? `${value.substring(0, 31)}... `
      : value;
  };

  const getDisplayTitle = (items) => {
    return items.length === options.length || items.length === 0
      ? "All"
      : items?.length > 2
      ? `${formatValue(items[0].name)}, ${formatValue(items[1].name)} and ${
          items.length - 2
        } more`
      : items.map((x) => formatValue(x.name)).join(", ");
  };

  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };

  return (
    <div ref={dropdownRef} onClick={handleClick}>
      <div className={styles.basicFilter}>
        {title} <span>Is </span>
        <div className={styles.displayValues}>
          <FilterAttributesDropdown
            styles={dropdownStyles}
            forBasicFilter={true}
            handleChangeAttributesValues={onChangeSelectedItems}
            options={options}
            keepInput={true}
            selectedItems={selectedItems}
            title={displayTitle}
            isOpen={openDropdown}
            multiSelect={filterDefinition.basicFilterConstraints.MultiSelect}
          />
        </div>
      </div>
    </div>
  );
}
