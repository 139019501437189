import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableViewIcon from "../../../../images/table-view-icon.svg";
import CardViewIcon from "../../../../images/card-view-icon.svg";

import * as Decorators from "../../../../styles/cell-decorators";
import styles from "./market-share-table.scss";

import utils from "../../../../utils";

import {
  fetchMarketShareData,
  changeMarketShareTableSorting,
  setCurrentViewIsUpdated,
  changeMarketShareSettings,
  changeSkusSelectedColumns,
} from "../../actions";

import {
  DEFAULT_SELECTED_KEYS,
  MAX_TABLE_RESULTS,
  MISSING_ATTRIBUTES_FILLER_KEY,
  DIMENSIONS,
  MARKET_SHARE_VIEW_TYPE_DATA,
} from "../../constants";

import _ from "lodash";
import SelectAllHeader from "./select-all-header";
import FilteredTable from "../../../../components/tables/filtered-table";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import { setSelectedSku } from "../../../product-360/actions";
import { dimensionDataKey } from "../../utils";
import MarketShareCards from "../market-share-cards/market-share-cards";
import ButtonGroup from "../../../../components/button-group/button-group";
import { analyticsSkuViewMode } from "../../../shared/analytics/actions";

const SKU_DIMENSIONS = [DIMENSIONS.sku, DIMENSIONS.family];

const {
  convertMarketShareTableSortValueToColumn,
  convertMarketShareTableSortValue2,
} = utils.marketShare;

function MarketShareTable(props) {
  const {
    setSelectedSku,
    insightsFiltersCollection,
    sortField,
    sortOrder,
    currentFrequency,
    selectedColumns,
    excludedTableColumns,
    marketShareSortedData,
    selectedCompetitor,
    selectedDateRange,
    currentViewConfigQuery,
    currentViewConfig,
    selectedPreviousDateRange,
    comparePeriod,
    selectedKeys,
    dimension,
    dimensionPivot,
    columnConfigs,
    onHoverRow,
  } = props;
  const defaultSelectedColumns = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8];
  const [
    updatedCurrentViewConfigQuery,
    setUpdatedCurrentViewConfigQuery,
  ] = useState();
  const [dateRangeTracker, setDateRange] = useState();
  const [previousPeriodTracker, setPreviousPeriodTracker] = useState();
  const [selectedSortingDirection, setSelectedSortingDirection] = useState(
    false
  );
  const [viewMode, setViewMode] = useState("table");
  const sortableColumns = [0, 1, 2, 3, 4, 5, 6, 7];
  const hasData = marketShareSortedData && marketShareSortedData.length > 0;

  const pivotConfig = () => {
    let config = {
      loaderWidth: LoaderWidths.Big,
    };

    switch (dimension) {
      case DIMENSIONS.brands:
        return {
          ...config,
          label: "Brand",
          accessor: "name",
          tooltip: "",
          cellDecorator: Decorators.noDecorator,
        };
      case DIMENSIONS.sellers:
        return {
          ...config,
          label: "Seller",
          accessor: "name",
          tooltip: "",
          cellDecorator: Decorators.noDecorator,
        };
      case DIMENSIONS.priceRange:
        return {
          ...config,
          label: "Price range",
          accessor: "name",
          tooltip: "",
          cellDecorator: Decorators.noDecorator,
        };
      case DIMENSIONS.sku:
        return {
          ...config,
          label: "Sku",
          accessor: "skuData",
          tooltip: "",
          cellDecorator: Decorators.marketShareSkuDecorator,
        };
      case DIMENSIONS.family:
        return {
          ...config,
          label: "Product Family",
          accessor: "skuData",
          tooltip: "",
          cellDecorator: Decorators.marketShareFamilyDecorator,
        };
      case DIMENSIONS.attribute:
        return {
          ...config,
          label: dimensionPivot,
          accessor: "name",
          tooltip: "",
          cellDecorator: Decorators.noDecorator,
        };
      default:
        return {
          ...config,
          label: "",
          accessor: "name",
          tooltip: "",
          cellDecorator: Decorators.noDecorator,
        };
    }
  };

  const requestedParams = {
    headersData: {
      groupBy: dimension,
    },
    insightViews: {
      ...currentViewConfigQuery,
      limit: MAX_TABLE_RESULTS,
      previousStartDate: selectedPreviousDateRange.previousStartDate,
      previousEndDate: selectedPreviousDateRange.previousEndDate,
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate,
      comparePeriod: comparePeriod,
    },
  };

  const initializedSelectedKeys = useRef(false);

  const setDefaultSelectedKeys = () => {
    props.changeMarketShareSettings({
      selectedKeys: DEFAULT_SELECTED_KEYS,
    });
  };

  useEffect(() => {
    if (marketShareSortedData && marketShareSortedData.length > 0) {
      if (!initializedSelectedKeys.current) {
        initializedSelectedKeys.current = true;
        if (selectedKeys === undefined) {
          setDefaultSelectedKeys();
        }
      } else {
        if (marketShareSortedData.length <= DEFAULT_SELECTED_KEYS.length) {
          let selectionBoundary;
          if (
            marketShareSortedData[marketShareSortedData.length - 1]?.name ===
            MISSING_ATTRIBUTES_FILLER_KEY
          )
            selectionBoundary = marketShareSortedData.length - 1;
          else selectionBoundary = marketShareSortedData.length;
          props.changeMarketShareSettings({
            selectedKeys: [...Array(selectionBoundary).keys()],
          });
        } else setDefaultSelectedKeys();
      }
    }
  }, [marketShareSortedData]);

  useEffect(() => {
    initializedSelectedKeys.current = false;
  }, [currentViewConfig.lastSync]);

  useEffect(() => {
    if (!(selectedColumns?.length > 0)) {
      onSaveSelectedColumns(defaultSelectedColumns);
    }
  }, [selectedColumns]);

  const onSelectionUpdate = (e, id, checked) => {
    e.preventDefault();
    e.stopPropagation();
    if (hasData) {
      if (checked) {
        if (!selectedKeys.includes(id)) {
          const newSelected = [...selectedKeys, id];
          newSelected.sort((a, b) => {
            return a - b;
          });
          props.changeMarketShareSettings({
            selectedKeys: newSelected,
          });
        }
      } else {
        if (selectedKeys.includes(id)) {
          const i = selectedKeys.indexOf(id);
          const newSelected = [...selectedKeys];
          newSelected.splice(i, 1);
          newSelected.sort((a, b) => {
            return a - b;
          });
          props.changeMarketShareSettings({
            selectedKeys: newSelected,
          });
        }
      }
    }
  };

  const onSaveSelectedColumns = (selectedColumnsIds) => {
    props.changeSkusSelectedColumns(selectedColumnsIds);
    props.setCurrentViewIsUpdated();
  };

  const columnField = convertMarketShareTableSortValueToColumn(sortField);

  const prepareSortingValues = (newSelectedSortingColumn) => {
    let sortDirection = false;
    if (columnField === newSelectedSortingColumn && selectedSortingDirection) {
      setSelectedSortingDirection(sortDirection);
    } else if (columnField === newSelectedSortingColumn) {
      sortDirection = true;
      setSelectedSortingDirection(sortDirection);
    } else {
      setSelectedSortingDirection(sortDirection);
    }

    const sortingConfig = {
      sortField: convertMarketShareTableSortValue2(
        newSelectedSortingColumn,
        currentFrequency
      ), //ToDO Convert
      sortOrder: sortDirection ? 0 : 1,
    };

    props.changeMarketShareTableSorting(sortingConfig);
    props.setCurrentViewIsUpdated();
  };

  const handleFilterChange = () => {
    // Liran: This is a trick to avoid re-calling fetchSearchTerms several times. Surely there's a better way to tackle this.
    if (shouldFetchData(requestedParams)) {
      // Verify offset is reset to 0
      requestedParams.insightViews.offset = 0;
      setUpdatedCurrentViewConfigQuery(requestedParams);
      setDateRange(selectedDateRange);
      setPreviousPeriodTracker(selectedPreviousDateRange);
      props.fetchMarketShareData(requestedParams);
    }
  };

  const shouldFetchData = (newUpdatedCurrentViewConfigQuery) => {
    // If view-config is updated, or if date-range is updated
    return (
      newUpdatedCurrentViewConfigQuery.headersData.groupBy !== undefined &&
      (!_.isEqual(
        newUpdatedCurrentViewConfigQuery,
        updatedCurrentViewConfigQuery
      ) ||
        !_.isEqual(dateRangeTracker, selectedDateRange) ||
        !_.isEqual(previousPeriodTracker, selectedPreviousDateRange))
    );
  };

  const handleComparePeriodChange = () => {
    if (shouldFetchData(requestedParams)) {
      // Verify offset is reset to 0
      requestedParams.insightViews.offset = 0;
      setUpdatedCurrentViewConfigQuery(requestedParams);
      setDateRange(selectedDateRange);
      setPreviousPeriodTracker(selectedPreviousDateRange);
      props.fetchMarketShareData(requestedParams);
    }
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    insightsFiltersCollection,
    sortField,
    sortOrder,
    selectedDateRange,
    selectedCompetitor,
    dimension,
    dimensionPivot,
  ]);

  useEffect(() => {
    handleComparePeriodChange();
  }, [comparePeriod]);

  const tableFilterFieldAccessor = () => {
    switch (dimension) {
      case 3:
      case 6:
        return "skuData.name";
      default:
        return "name";
    }
  };

  const maxExportRows = () => {
    switch (dimension) {
      case DIMENSIONS.sku:
        return "1,000";
      default:
        return "50,000";
    }
  };

  const columnsConfig = [
    {
      id: -2,
      label: "Selected",
      accessor: "selected",
      tooltip: "The name of the seller or brand",
      cellDecorator: Decorators.checkboxDecorator(
        onSelectionUpdate,
        selectedKeys ?? []
      ),
      Header: (
        <SelectAllHeader
          selectedKeys={selectedKeys ?? []}
          setSelectedKeys={(keys) =>
            hasData
              ? props.changeMarketShareSettings({ selectedKeys: keys })
              : ""
          }
        />
      ),
    },
    {
      id: -1,
      ...pivotConfig(),
    },
  ].concat(columnConfigs);

  const onEntityClick = (selectedEntity) => {
    if (dimension === DIMENSIONS.sku) {
      setSelectedSku(selectedEntity.name);
    } else if (dimension === DIMENSIONS.family) {
      // take the first sku in the family
      setSelectedSku(selectedEntity.skuData.sku.split(",")[0]);
    }
  };

  const handleRowHover = (row) => {
    if (onHoverRow) onHoverRow(dimensionDataKey(row, dimension));
  };

  const onChangeViewMode = (x) => {
    setViewMode(x);
    analyticsSkuViewMode(MARKET_SHARE_VIEW_TYPE_DATA.id, x);
  };

  return (
    <div
      id={hasData ? "marketShareTable" : undefined}
      style={{ width: "100%" }}
      onMouseLeave={() => onHoverRow(null)}
    >
      {SKU_DIMENSIONS.includes(dimension) && (
        <div style={{ float: "right", marginBottom: "8px" }}>
          <ButtonGroup
            buttons={[
              { id: "table", image: TableViewIcon, label: "Table" },
              { id: "cards", image: CardViewIcon, label: "Cards" },
            ]}
            toggledButton={viewMode}
            onToggle={(x) => onChangeViewMode(x)}
          />
        </div>
      )}
      {SKU_DIMENSIONS.includes(dimension) && viewMode == "cards" ? (
        <MarketShareCards
          data={marketShareSortedData}
          selectedKeys={selectedKeys}
          onClick={onEntityClick}
          onSelectionUpdate={onSelectionUpdate}
        />
      ) : (
        <FilteredTable
          header="Market Share Table"
          columnsConfig={columnsConfig}
          onSaveSelectedColumns={onSaveSelectedColumns}
          excludedTableColumns={excludedTableColumns}
          sorting={{
            sortingColumn: columnField,
            selectedSortingDirection: !sortOrder,
          }}
          selectedColumns={selectedColumns}
          pinnedColumns={[-2, -1]}
          sortableColumns={sortableColumns}
          prepareSortingValues={prepareSortingValues}
          styles={styles}
          data={marketShareSortedData}
          maxTableResults={MAX_TABLE_RESULTS}
          maxExportRows={maxExportRows()}
          filterFieldAccessor={tableFilterFieldAccessor()}
          onSelectRow={onEntityClick}
          onHoverRow={handleRowHover}
          isLoading={!hasData}
        />
      )}
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    loading: state.marketShare.insights.insights.loading,
    tableStatus: state.marketShare.marketShare.tableStatus,
    marketShareSortedData: state.marketShare.marketShare.marketShareSortedData,
    selectedCompetitor:
      state.marketShare.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.marketShare.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.marketShare.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.marketShare.insights.mbFilterRow.comparePeriod,

    //ToDo should refactored
    currentViewConfig: state.marketShare.insights.insights.currentViewConfig,
    currentViewConfigQuery:
      state.marketShare.insights.insights.currentViewConfig.query,
    insightsFiltersCollection:
      state.marketShare.insights.insights.currentViewConfig.query
        .productAttributes,
    sortField:
      state.marketShare.insights.insights.currentViewConfig.query.sortField,
    sortOrder:
      state.marketShare.insights.insights.currentViewConfig.query.sortOrder,
    offset: state.marketShare.insights.insights.currentViewConfig.query.offset,
    selectedKeys:
      state.marketShare.insights.insights.currentViewConfig.query.settings
        .selectedKeys,
    dimension:
      state.marketShare.insights.insights.currentViewConfig.query.settings
        .dimension,
    dimensionPivot:
      state.marketShare.insights.insights.currentViewConfig.query.settings
        .dimensionPivot,
    productAttributes:
      state.marketShare.insights.insights.currentViewConfig.query
        .productAttributes,
    selectedColumns:
      state.marketShare.insights.insights.currentViewConfig.query
        .selectedColumns,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMarketShareData,
      setCurrentViewIsUpdated,
      changeMarketShareTableSorting,
      changeMarketShareSettings,
      setSelectedSku,
      changeSkusSelectedColumns,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarketShareTable);
