import React from "react";
import moment from "moment";
import XYChartWrapper from "../../../visx_components/xychart/xychart";
import LegendWrapper from "../../../visx_components/legend/side-legend";
import styles from "./base-chart-component.scss";
import BaseChartComponent from "./base-chart-component";

import LineHighlighter from "./line-highlighter";

export default function LineChartComponent(props) {
  const {
    id,
    data,
    previousData,
    dataKeys = {},
    xValuesNumeric,
    xAxis,
    xScaleType,
    xLabel,
    xKey,
    yTickFormat = (x) => x,
    tooltipCallback,
    chartName,
    baseStyles,
    xNumTicks,
    highlightKey,
    hideLegend = false,
    stack = false,
    legendLabelProps,
  } = props;

  const chart = (highlightKey) => (
    <BaseChartComponent styles={baseStyles} data={data}>
      <div style={{ width: "100%", position: "relative" }}>
        <div className={styles.chartName}>{chartName}</div>
        <XYChartWrapper
          id={id}
          data={data}
          previousData={previousData}
          dataKeys={dataKeys}
          xAxis={xAxis}
          xLabel={xLabel}
          xKey={xKey}
          xValuesNumeric={xValuesNumeric}
          xScaleType={xScaleType}
          xNumTicks={xNumTicks}
          curveType={"monotoneCurve"}
          xTickFormat={(x) => moment(x).format("D MMM YY")}
          yTickFormat={yTickFormat}
          showGridColumns={false}
          tooltipCallback={tooltipCallback}
          highlightKey={highlightKey}
          chartType={stack ? "lineStack" : "lineGroup"}
        ></XYChartWrapper>
      </div>
      <div className={styles.chartInfoWrapper}>
        {!hideLegend && (
          <div className={styles.legendLine}>
            <LegendWrapper
              labels={Object.keys(dataKeys)}
              labelsProps={legendLabelProps}
              width={16}
            />
          </div>
        )}
      </div>
    </BaseChartComponent>
  );

  return (
    <>
      {highlightKey === undefined ? (
        <LineHighlighter>
          {(highlightKey) => chart(highlightKey)}
        </LineHighlighter>
      ) : (
        chart(highlightKey)
      )}
    </>
  );
}
