import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  InsightsMainSpinner,
  InsightsDataControls,
  InsightsDetails,
} from "../../containers/insights";
import InsightsFilter from "../../mb_components/mb-insights-filter";
import { clearSkusItems } from "../../containers/insights/insights-skus/actions";
import styles from "./insights-template.scss";
import {
  fetchInsightsViewsDefinitions,
  fetchInsightsViewsConfigs,
} from "../../containers/insights/insights-data-controls/actions";
import { changeFieldCompetitors } from "../../containers/insights/insights-header-filter/actions";
import {
  updateViewType,
  clearInsights,
} from "../../containers/insights/actions";

import {
  setSelectedDateRange,
  setChosenPeriod,
  setPreviousSelectedDateRange,
  setComparePeriod,
} from "../../mb_components/mb-filter-row/actions";

import {
  getDatesByChosenPeriod,
  getDateRangeInfo,
  getPreviousDates,
} from "../../utils/other/dates";
import { analyticsModuleLoad } from "../../containers/shared/analytics/actions";
import InsightsTemplateLandingPage from "../../mb_components/mb-insights-template-landing-page/insights-template-landing-page";
import {
  hasSelectedCategories,
  hasSelectedRetailers,
} from "../../containers/market-share/utils";
import MbInsightsAiSidebar from "../../containers/insights/insights-ai-sidebar";
import { MARKET_SHARE_VIEW_TYPE_DATA } from "../../containers/market-share/constants";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../../containers/insights-board/constants";

class InsightsTemplate extends Component {
  fetchInsightsViewsDefinitions = () => {
    this.props.fetchInsightsViewsDefinitions(
      this.props.apiProvider,
      this.props.viewTypeData.id,
      this.props.dateRange,
      this.applyQueryStringToFilters
    );
  };

  componentDidMount() {
    this.props.updateViewType(this.props.viewTypeData);
    analyticsModuleLoad(this.props.viewTypeData.id);
    this.fetchInsightsViewsDefinitions();
    this.props.fetchInsightsViewsConfigs(
      this.props.apiProvider,
      this.props.viewTypeData.id
    );
  }

  componentWillUnmount() {
    this.props.clearInsights();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lastAvailableDate &&
      this.props.lastAvailableDate &&
      prevProps.lastAvailableDate.toDateString() !==
        this.props.lastAvailableDate.toDateString()
    ) {
      const id = parseInt(this.props.rollingId);
      // Means that it's rolling period.
      if (id !== 0) {
        var newDateRange = getDateRangeInfo(
          this.props.lastAvailableDate,
          id,
          this.props.selectedDateRange
        );
        delete newDateRange["chosenPeriod"];
        newDateRange.rolling = id;
        this.props.setSelectedDateRange(newDateRange);
        if (!this.props.hideCompareDates) {
          this.props.setPreviousSelectedDateRange(
            getPreviousDates(newDateRange, this.props.comparePeriod)
          );
        }
      }
    }
    if (
      prevProps.dateRange?.startDate != "" &&
      prevProps.dateRange?.endDate != "" &&
      JSON.stringify(prevProps.dateRange) !==
        JSON.stringify(this.props.dateRange)
    ) {
      this.fetchInsightsViewsDefinitions();
    }
  }

  doesSupportLandingPage = () => {
    const viewTypesSupportLandingPage = [
      MARKET_SHARE_VIEW_TYPE_DATA.id,
      ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA.id,
    ];
    return viewTypesSupportLandingPage.some(
      (item) => item === this.props.viewTypeData.id
    );
  };

  render() {
    const {
      chosenPeriod,
      selectedDateRange,
      selectedCompetitor,
      selectedTimeFrequency,
      competitorsOptions,
      viewDefinitions,
      noDataMessage,
      dataControlsOptions,
      body,
      insightsFilterShowSidebar,
      insightsAiComponent,
      hideInsightsFilter = false,
    } = this.props;

    const insightsDataControls = dataControlsOptions ? (
      <div className={styles.content}>
        <InsightsDataControls
          dataControlsOptions={{
            ...dataControlsOptions,
            chosenPeriod,
            selectedDateRange,
            selectedCompetitor,
            selectedTimeFrequency,
            competitorsList: competitorsOptions,
            convertBrowserStringSortFieldToConfig: this.props
              .convertBrowserStringSortFieldToConfig,
          }}
        />
      </div>
    ) : null;

    const hasFilterDefinitions = viewDefinitions?.filterDefinitions?.length;
    const mainSpinner = !hasFilterDefinitions ? <InsightsMainSpinner /> : null;
    const pageData = hasFilterDefinitions
      ? noDataMessage
        ? noDataMessage
        : body
      : null;
    const details = hasFilterDefinitions ? <InsightsDetails /> : null;
    const landing = () => {
      if (!this.doesSupportLandingPage()) return null;
      if (!hasSelectedRetailers(this.props.currentViewConfig))
        return (
          <InsightsTemplateLandingPage
            displayText={"Select a retailer and a category to get insights"}
            viewTypeId={this.props.viewTypeData.id}
          />
        );
      if (!hasSelectedCategories(this.props.currentViewConfig))
        return (
          <InsightsTemplateLandingPage
            viewTypeId={this.props.viewTypeData.id}
          />
        );
    };

    const displayContent = (
      <div className={styles.insightsTemplateWrapper}>
        {!hideInsightsFilter && (
          <div
            className={styles.insightsTemplateLeftBar}
            style={{ width: insightsFilterShowSidebar ? "350px" : "20px" }}
          >
            <InsightsFilter
              lastAvailableDate={this.props.lastAvailableDate}
              apiProvider={dataControlsOptions.apiProvider}
              viewTypeData={this.props.viewTypeData}
              minimizeByDefault={
                dataControlsOptions.minimizeInsightsFilterByDefault
              }
              hideViewManager={dataControlsOptions.hideViewManager}
            />
          </div>
        )}
        <div className={styles.rightBlock}>
          {insightsDataControls}
          {landing() ?? (
            <>
              {mainSpinner}
              {pageData}
            </>
          )}
        </div>
      </div>
    );

    return (
      <Fragment>
        {displayContent}
        {details}
      </Fragment>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    // competitorSelected: state.insights.insights.competitor.rootDomain,
    competitorsOptions: state.competitors.data,
    viewDefinitions: state.insights.insights.viewDefinitions,
    chosenPeriod: state.insights.mbFilterRow.chosenPeriod,
    rollingId: state.insights.mbFilterRow.rolling,
    selectedCompetitor: state.insights.mbFilterRow.selectedCompetitor,
    selectedTimeFrequency: state.insights.mbFilterRow.selectedTimeFrequency,
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.insights.mbFilterRow.comparePeriod,
    currentViewConfig: state.insights.insights.currentViewConfig,
    insightsFilterShowSidebar:
      state.insights.insights.insightsFilterShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSkusItems,
      changeFieldCompetitors,
      fetchInsightsViewsDefinitions,
      fetchInsightsViewsConfigs,
      updateViewType,
      clearInsights,
      setSelectedDateRange,
      setChosenPeriod,
      setPreviousSelectedDateRange,
      setComparePeriod,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InsightsTemplate);
