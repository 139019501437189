import React, { useState, useEffect } from "react";
import styles from "./brand-performance-infinite-table.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteDataTable from "../../../../components/infinite-data-table/infinite-data-table";

import {
  fetchMoreBrandPerformanceTable,
  fetchBrandPerformanceTable,
  addSelectedSearchTermGraph,
  removeSelectedSearchTermGraph,
} from "../../actions";

import {
  updateCurrentViewConfigQuery,
  setCurrentViewIsUpdated,
} from "../../../insights/insights-data-controls/actions";

import _ from "lodash";
import MbBasicFilters from "../../../../mb_components/mb-basic-filters/mb-basic-filters";
import { BRAND_PERFORMANCE_VIEW_TYPE_DATA } from "../../constants";
import BrandPerformanceTable from "./brand-performance-table";

function BrandPerformanceInfiniteTable(props) {
  const {
    insightsFiltersCollection,
    sortField,
    sortOrder,
    tableData,
    selectedDateRange,
    selectedPreviousDateRange,
    currentViewConfigQuery,
    viewDefinitions,
    loading,
    hasMore,
    selectedSearchTermsGraphs,
  } = props;

  const [
    updatedCurrentViewConfigQuery,
    setUpdatedCurrentViewConfigQuery,
  ] = useState();
  const [dateRangeTracker, setDateRange] = useState();

  const requestedParams = {
    ...currentViewConfigQuery,
    startDate: selectedDateRange.startDate,
    endDate: selectedDateRange.endDate,
    previousStartDate: selectedPreviousDateRange.previousStartDate,
    previousEndDate: selectedPreviousDateRange.previousEndDate,
  };

  const handleFilterChange = () => {
    // Liran: This is a trick to avoid re-calling fetchSearchTerms several times. Surely there's a better way to tackle this.
    if (shouldFetchData(requestedParams)) {
      setUpdatedCurrentViewConfigQuery(requestedParams);
      setDateRange(selectedDateRange);
      props.fetchBrandPerformanceTable(requestedParams);
    }
  };

  const shouldFetchData = (newUpdatedCurrentViewConfigQuery) => {
    // If view-config is updated, or if date-range is updated
    return (
      JSON.stringify(newUpdatedCurrentViewConfigQuery) !==
        JSON.stringify(updatedCurrentViewConfigQuery) ||
      JSON.stringify(dateRangeTracker) !== JSON.stringify(selectedDateRange)
    );
  };

  const updateFilters = (filters) => {
    props.updateCurrentViewConfigQuery({
      productAttributes: filters,
    });
    props.setCurrentViewIsUpdated();
  };

  const fetchMoreData = () => {
    props.fetchMoreBrandPerformanceTable(requestedParams);
  };

  useEffect(() => {
    handleFilterChange();
  }, [insightsFiltersCollection, sortField, sortOrder, selectedDateRange]);

  const table = (
    <BrandPerformanceTable
      data={tableData}
      addSelectedSearchTermGraph={props.addSelectedSearchTermGraph}
      removeSelectedSearchTermGraph={props.removeSelectedSearchTermGraph}
      selectedSearchTermsGraphs={selectedSearchTermsGraphs}
    />
  );

  const NoDataComponent = () => {
    return (
      <div className={styles.noData}>
        <div>Please select a brand</div>
      </div>
    );
  };

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.controlsWrapper}>
        <MbBasicFilters
          filterDefinitions={viewDefinitions?.filterDefinitions}
          insightsFiltersCollection={insightsFiltersCollection}
          updateFilters={updateFilters}
          viewTypeId={BRAND_PERFORMANCE_VIEW_TYPE_DATA.id}
        />
      </div>
      <InfiniteDataTable
        table={table}
        noDataComponent={<NoDataComponent />}
        dataLength={tableData?.length ?? 0}
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        loading={loading}
        loadingMore={false}
      />
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    loading: state.insights.insights.loading,
    tableData: state.brandPerformance.tableData,
    hasMore: state.brandPerformance.hasMore,
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.insights.mbFilterRow.selectedPreviousDateRange,
    viewDefinitions: state.insights.insights.viewDefinitions,
    filterDefinitions:
      state.insights.insights.viewDefinitions.filterDefinitions,

    //ToDo should refactored
    currentViewConfig: state.insights.insights.currentViewConfig,
    currentViewConfigQuery: state.insights.insights.currentViewConfig.query,
    insightsFiltersCollection:
      state.insights.insights.currentViewConfig.query.productAttributes,
    offset: state.insights.insights.currentViewConfig.query.offset,

    selectedSearchTermsGraphs: state.brandPerformance.selectedSearchTermsGraphs,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMoreBrandPerformanceTable,
      fetchBrandPerformanceTable,
      updateCurrentViewConfigQuery,
      setCurrentViewIsUpdated,
      removeSelectedSearchTermGraph,
      addSelectedSearchTermGraph,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandPerformanceInfiniteTable);
