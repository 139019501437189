import {
  APPLY_INITIAL_STATE,
  FETCH_TABLE_REQUEST,
  FETCH_TABLE_SUCCESS,
  FETCH_TABLE_FAILURE,
  FETCH_TABLE_CANCEL,
  FETCH_MORE_TABLE_REQUEST,
  FETCH_MORE_TABLE_SUCCESS,
  FETCH_MORE_TABLE_FAILURE,
  FETCH_MORE_TABLE_CANCEL,
  FETCH_GRAPH_REQUEST,
  FETCH_GRAPH_SUCCESS,
  FETCH_GRAPH_FAILURE,
  FETCH_GRAPH_CANCEL,
  REMOVE_SELECTED_SEARCH_TERM_GRAPH,
  ADD_SELECTED_SEARCH_TERM_GRAPH,
  CHANGE_SELECTED_OPTIONS_PER_GRAPH,
} from "./constants";

import BrandPerformanceService from "../../services/brandPerformanceService";

const brandPerformanceService = new BrandPerformanceService();

export const removeSelectedSearchTermGraph = (searchTerm) => ({
  type: REMOVE_SELECTED_SEARCH_TERM_GRAPH,
  payload: { searchTerm },
});

export const addSelectedSearchTermGraph = (searchTerm) => ({
  type: ADD_SELECTED_SEARCH_TERM_GRAPH,
  payload: { searchTerm },
});

export const changeSelectedOptionsPerGraph = (searchTerm, id, value) => ({
  type: CHANGE_SELECTED_OPTIONS_PER_GRAPH,
  payload: { searchTerm, id, value },
});

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const tableRequested = () => ({
  type: FETCH_TABLE_REQUEST,
});

export const tableSuccess = (data) => ({
  type: FETCH_TABLE_SUCCESS,
  payload: { data },
});

export const tableError = (error) => {
  return {
    type: FETCH_TABLE_FAILURE,
    payload: { error },
  };
};

export const tableCancel = () => ({
  type: FETCH_TABLE_CANCEL,
});

export const fetchBrandPerformanceTable = (requestParams) => (dispatch) => {
  dispatch(tableRequested());

  brandPerformanceService
    .fetchBrandPerformanceTable(requestParams)
    .then((res) => {
      dispatch(tableSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(tableCancel());
      } else {
        dispatch(tableError(err.message));
      }
    });
};

export const moreTableRequested = () => ({
  type: FETCH_MORE_TABLE_REQUEST,
});

export const moreTableSuccess = (data) => ({
  type: FETCH_MORE_TABLE_SUCCESS,
  payload: { data },
});

export const moreTableError = (error) => {
  return {
    type: FETCH_MORE_TABLE_FAILURE,
    payload: { error },
  };
};

export const moreTableCancel = () => ({
  type: FETCH_MORE_TABLE_CANCEL,
});

export const fetchMoreBrandPerformanceTable = (requestParams) => (dispatch) => {
  dispatch(moreTableRequested());

  brandPerformanceService
    .fetchBrandPerformanceTable(requestParams)
    .then((res) => {
      dispatch(moreTableSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(moreTableCancel());
      } else {
        dispatch(moreTableError(err.message));
      }
    });
};

export const graphRequested = (searchTerm, id) => ({
  type: FETCH_GRAPH_REQUEST,
  payload: { searchTerm, id },
});

export const graphSuccess = (searchTerm, id, data) => ({
  type: FETCH_GRAPH_SUCCESS,
  payload: { searchTerm, id, data },
});

export const graphError = (searchTerm, id, error) => {
  return {
    type: FETCH_GRAPH_FAILURE,
    payload: { searchTerm, id, error },
  };
};

export const graphCancel = (searchTerm, id) => ({
  type: FETCH_GRAPH_CANCEL,
  payload: { searchTerm, id },
});

export const fetchBrandPerformanceGraph = (
  requestParams,
  searchTerm,
  id,
  type
) => (dispatch) => {
  dispatch(graphRequested(searchTerm, id));

  brandPerformanceService
    .fetchBrandPerformanceGraph(requestParams, searchTerm, type)
    .then((res) => {
      dispatch(graphSuccess(searchTerm, id, res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(graphCancel(searchTerm, id));
      } else {
        dispatch(graphError(searchTerm, id, err.message));
      }
    });
};

export const fetchBrandPerformanceForAllGraphs = (
  requestParams,
  searchTerm,
  types
) => (dispatch) => {
  const promises = types.map((type, id) => {
    dispatch(graphRequested(searchTerm, id));
    brandPerformanceService
      .fetchBrandPerformanceGraph(requestParams, searchTerm, type, true)
      .then((res) => {
        dispatch(graphSuccess(searchTerm, id, res));
      })
      .catch((err) => {
        if (err.message === "cancel") {
          dispatch(graphCancel(searchTerm, id));
        } else {
          dispatch(graphError(searchTerm, id, err.message));
        }
      });
  });
  Promise.all(promises);
};
