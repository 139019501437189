import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./brand-performance-linecharts.scss";
import BrandPerformanceLineChart from "./brand-performance-linechart";
import { DEFAULT_GRAPH_OPTIONS } from "../../constants";
import {
  fetchBrandPerformanceGraph,
  fetchBrandPerformanceForAllGraphs,
  changeSelectedOptionsPerGraph,
} from "../../actions";

function BrandPerformanceLineCharts(props) {
  const {
    data,
    graphData,
    selectedDateRange,
    selectedPreviousDateRange,
    currentViewConfigQuery,
    fetchBrandPerformanceGraph,
    fetchBrandPerformanceForAllGraphs,
    graphOptions,
    changeSelectedOptionsPerGraph,
  } = props;
  const searchTermGraphsData = graphData ? graphData[data.searchTerm] : false;
  const [graphsTypes, setGraphsTypes] = useState(
    graphOptions?.[data.searchTerm] ?? DEFAULT_GRAPH_OPTIONS
  );

  const onGraphTypeChange = (id) => (graphType) => {
    console.log("Changing ", id, " to ", graphType);
    changeSelectedOptionsPerGraph(data.searchTerm, id, graphType.value);
    let newGraphsTypes = [...graphsTypes];
    newGraphsTypes[id] = graphType.value;
    setGraphsTypes(newGraphsTypes);
    fetchBrandPerformanceGraph(
      requestedParams,
      data.searchTerm,
      id,
      graphType.value
    );
  };

  const requestedParams = {
    ...currentViewConfigQuery,
    startDate: selectedDateRange.startDate,
    endDate: selectedDateRange.endDate,
    previousStartDate: selectedPreviousDateRange.previousStartDate,
    previousEndDate: selectedPreviousDateRange.previousEndDate,
  };

  useEffect(() => {
    if (!searchTermGraphsData) {
      fetchBrandPerformanceForAllGraphs(
        requestedParams,
        data.searchTerm,
        graphsTypes
      );
    }
  }, [graphData]);

  return (
    <>
      {searchTermGraphsData ? (
        <div className={styles.mainWrapper}>
          <div className={styles.secondaryWrapper}>
            <BrandPerformanceLineChart
              data={searchTermGraphsData[0]}
              graphType={graphsTypes[0]}
              onGraphTypeChange={(graphType) => onGraphTypeChange(0)(graphType)}
            />
            <BrandPerformanceLineChart
              data={searchTermGraphsData[1]}
              graphType={graphsTypes[1]}
              onGraphTypeChange={(graphType) => onGraphTypeChange(1)(graphType)}
            />
          </div>
          <div className={styles.secondaryWrapper}>
            <BrandPerformanceLineChart
              data={searchTermGraphsData[2]}
              graphType={graphsTypes[2]}
              onGraphTypeChange={(graphType) => onGraphTypeChange(2)(graphType)}
            />
            <BrandPerformanceLineChart
              data={searchTermGraphsData[3]}
              graphType={graphsTypes[3]}
              onGraphTypeChange={(graphType) => onGraphTypeChange(3)(graphType)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export const mapStateToProps = (state) => {
  return {
    graphData: state.brandPerformance.graphData,
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.insights.mbFilterRow.selectedPreviousDateRange,
    currentViewConfigQuery: state.insights.insights.currentViewConfig.query,
    graphOptions: state.brandPerformance.graphOptions,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBrandPerformanceGraph,
      fetchBrandPerformanceForAllGraphs,
      changeSelectedOptionsPerGraph,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandPerformanceLineCharts);
