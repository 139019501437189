import styles from "./insights-template-landing-page.scss";
import React from "react";
import pic from "../../images/no-category-selected.svg";

function InsightsTemplateLandingPage(props) {
  var learnMoreText =
    props.viewTypeId === 3 ? "sales & market share" : "account performance";
  var displayText = props.displayText
    ? props.displayText
    : "Select at least one category to get insights.";
  return (
    <div className={styles.landingPageContainer}>
      <div className={styles.imageContainer}>
        <img src={pic} />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.selectCategoryText}>{displayText}</p>

        <p className={styles.learnMoreText}>
          Learn{" "}
          <a className={styles.linkToLearnMore} id="learnMoreText">
            how to use the filter
          </a>{" "}
          to generate custom {learnMoreText} analytics.
        </p>
      </div>
    </div>
  );
}

export default InsightsTemplateLandingPage;
