import { isNull } from "lodash";

const arraysIntersection = (arrs) =>
  arrs.reduce((a, b) => a.filter((c) => b.includes(c)));

export function UpdateFilterBySubjectTo(filter, filtersCollection) {
  if (
    filter.subjectTo?.length > 0 &&
    filter.subjectTo.every((x) =>
      filtersCollection.some((filter) => filter.mbName == x)
    )
  ) {
    switch (filter.type) {
      case "CollectionDictionary":
        const nestedValues = [];
        fixSubjectTo(filter, filtersCollection, filter.values, nestedValues);
        if (nestedValues.length === 0) break;
        const nestedIntersection = arraysIntersection(
          nestedValues.map((x) => Object.keys(x))
        );
        const resultDict = {};
        for (const sharedKey of nestedIntersection) {
          resultDict[sharedKey] = arraysIntersection(
            nestedValues.map((x) => x[sharedKey])
          );
        }
        filter.values = resultDict;
        break;
      default:
        break;
    }
  }
  return filter;
}

function fixSubjectTo(
  filter,
  filtersCollection,
  nestedDict,
  keepNested,
  currentDepth = 0
) {
  const maxDepth = filter.subjectTo.length;
  if (currentDepth == maxDepth) {
    keepNested.push(nestedDict);
    return;
  }

  const subjectName = filter.subjectTo[currentDepth];
  const subjectFilter = filtersCollection.find((x) => x.mbName === subjectName);

  const selectedValues = Array.isArray(subjectFilter.selectedProperties)
    ? subjectFilter.selectedProperties
    : [subjectFilter.selectedProperties];
  for (const value of selectedValues) {
    if (isNull(nestedDict) || !nestedDict[value]) {
      keepNested = [];
      break;
    }
    fixSubjectTo(
      filter,
      filtersCollection,
      nestedDict[value],
      keepNested,
      currentDepth + 1
    );
  }
}

export function CategoryLeaf(category) {
  if (category) return category.split(">").splice(-1)[0];
  return "";
}
