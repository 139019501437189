const path = "insights/insights";
export const SET_CURRENT_VIEW_IS_UPDATED = `${path}/SET_CURRENT_VIEW_IS_UPDATED`;
export const SET_INITIAL_VIEW_LOADED = `${path}/SET_INITIAL_VIEW_LOADED`;
export const SET_CURRENT_VIEW_CONFIG = `${path}/SET_CURRENT_VIEW_CONFIG`;
export const UPDATE_CURRENT_VIEW_CONFIG = `${path}/UPDATE_CURRENT_VIEW_CONFIG`;
export const UPDATE_CURRENT_VIEW_CONFIG_QUERY = `${path}/UPDATE_CURRENT_VIEW_CONFIG_QUERY`;

export const FETCH_VIEWS_DEFINITIONS_REQUEST = `${path}/FETCH_VIEWS_DEFINITIONS_REQUEST`;
export const FETCH_VIEWS_DEFINITIONS_FAILURE = `${path}/FETCH_VIEWS_DEFINITIONS_FAILURE`;
export const FETCH_VIEWS_DEFINITIONS_SUCCESS = `${path}/FETCH_VIEWS_DEFINITIONS_SUCCESS`;
export const FETCH_VIEWS_DEFINITIONS_CANCEL = `${path}/FETCH_VIEWS_DEFINITIONS_CANCEL`;

export const FETCH_FILTER_DEFINITIONS_REQUEST = `${path}/FETCH_FILTER_DEFINITIONS_REQUEST`;
export const FETCH_FILTER_DEFINITIONS_FAILURE = `${path}/FETCH_FILTER_DEFINITIONS_FAILURE`;
export const FETCH_FILTER_DEFINITIONS_SUCCESS = `${path}/FETCH_FILTER_DEFINITIONS_SUCCESS`;
export const FETCH_FILTER_DEFINITIONS_CANCEL = `${path}/FETCH_FILTER_DEFINITIONS_CANCEL`;

export const SAVE_INSIGHTS_VIEW_CONFIG_REQUEST = `${path}/SAVE_INSIGHTS_VIEW_CONFIG_REQUEST`;
export const SAVE_INSIGHTS_VIEW_CONFIG_FAILURE = `${path}/SAVE_INSIGHTS_VIEW_CONFIG_FAILURE`;
export const SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS = `${path}/SAVE_INSIGHTS_VIEW_CONFIG_SUCCESS`;
export const SAVE_INSIGHTS_VIEW_CONFIG_CANCEL = `${path}/SAVE_INSIGHTS_VIEW_CONFIG_CANCEL`;

export const FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST = `${path}/FETCH_INSIGHTS_VIEWS_CONFIGS_REQUEST`;
export const FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE = `${path}/FETCH_INSIGHTS_VIEWS_CONFIGS_FAILURE`;
export const FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS = `${path}/FETCH_INSIGHTS_VIEWS_CONFIGS_SUCCESS`;
export const FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL = `${path}/FETCH_INSIGHTS_VIEWS_CONFIGS_CANCEL`;

export const UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST = `${path}/UPDATE_INSIGHTS_VIEW_CONFIG_REQUEST`;
export const UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE = `${path}/UPDATE_INSIGHTS_VIEW_CONFIG_FAILURE`;
export const UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS = `${path}/UPDATE_INSIGHTS_VIEW_CONFIG_SUCCESS`;
export const UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL = `${path}/UPDATE_INSIGHTS_VIEW_CONFIG_CANCEL`;

export const DELETE_INSIGHTS_VIEW_CONFIG_REQUEST = `${path}/DELETE_INSIGHTS_VIEW_CONFIG_REQUEST`;
export const DELETE_INSIGHTS_VIEW_CONFIG_FAILURE = `${path}/DELETE_INSIGHTS_VIEW_CONFIG_FAILURE`;
export const DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS = `${path}/DELETE_INSIGHTS_VIEW_CONFIG_SUCCESS`;
export const DELETE_INSIGHTS_VIEW_CONFIG_CANCEL = `${path}/DELETE_INSIGHTS_VIEW_CONFIG_CANCEL`;

export const EXPORT_INSIGHTS_VIEW_REQUEST = `${path}/EXPORT_INSIGHTS_VIEW_REQUEST`;
export const EXPORT_INSIGHTS_VIEW_FAILURE = `${path}/EXPORT_INSIGHTS_VIEW_FAILURE`;
export const EXPORT_INSIGHTS_VIEW_SUCCESS = `${path}/EXPORT_INSIGHTS_VIEW_SUCCESS`;
export const EXPORT_INSIGHTS_VIEW_CANCEL = `${path}/EXPORT_INSIGHTS_VIEW_CANCEL`;
export const REMOVE_EXPORTED_INSIGHTS_VIEW_DATA = `${path}/REMOVE_EXPORTED_INSIGHTS_VIEW_DATA`;
