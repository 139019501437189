import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import { applyMiddleware } from "redux-subspace";
import thunk from "redux-thunk";
import history from "./history";

import rootReducer from "./reducer";

import "./styles/global.scss";
import App from "./containers/app";

import IntercomWidget from "./components/intercom";

const initialState = {};
const enhancers = [];
const middleware = [thunk];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === "function") {
  enhancers.push(devToolsExtension({ trace: true }));
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

render(
  <Provider store={store}>
    <IntercomWidget />
    <App history={history} />
  </Provider>,
  document.getElementById("root")
);
